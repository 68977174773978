import React, { useEffect, useState, useRef } from "react";
import { Outlet, Link, useNavigate, useLocation } from "react-router-dom";
import ReactSearchBox from "react-search-box";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getRestaurantDetails } from "../services/UserLogin.service";
import { useDispatch, useSelector } from "react-redux";
import {
  removePreLoginCartItem,
  restaurantDetailsAction,
  userCartItems,
} from "../redux/actions/Actions";
import {
  getSubCategory,
  getSearchProducts,
} from "../services/Products.service";
import { getCartItems, deleteCartItem } from "../services/Cart.service";
import { PATH } from "../redux/constants/Constants";
import { getAllCoupons } from "../services/Order.service";

export default function Site() {
  const [restaurantDetails, setRestaurantDetails] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [placeholderText, setPlaceholderText] = useState("");
  const [menSubCategory, setMenSubCategory] = useState("");
  const [womenSubCategory, setWomenSubCategory] = useState("");
  const [cartVisible, setCartVisible] = useState(false);
  const cartItemList = useSelector(
    (state) => state?.cartItems?.cartItems?.results
  );
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
  const [menMenuVisible, setMenMenuVisible] = useState(false);
  const [womenMenuVisible, setWomenMenuVisible] = useState(false);
  const [clickVisible, setClickVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [productSuggestions, setProductSuggestions] = useState(null);
  const [searchBoxVisible, setSearchBoxVisible] = useState(true);

  // const [cartCount, setCartCount] = useState(useSelector((state) => state.cartItems.cartItems.count))
  // console.log(cartItemList);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const placeholder = "Search For";
  const token = useSelector((state) => state.status?.restaurant?.Token);
  const userData = useSelector((state) => state.user?.user);
  const cartId = useSelector(
    (state) => state.cart.cart.results && state.cart?.cart?.results[0].id
  );
  const cartCount = useSelector((state) => state?.cartItems?.cartItems?.count);
  const currencySymbol = useSelector(
    (state) => state.status?.restaurant?.currency_symbol
  );
  const node = useRef();
  const logo = useSelector((state) => state.status?.restaurant?.compony_image);
  const preLoginCount = useSelector(
    (state) => state?.preLoginCart?.preLoginCart?.length
  );
  // console.log(preLoginCount);
  const preLoginCart = useSelector(
    (state) => state?.preLoginCart?.preLoginCart
  );
  // console.log(preLoginCart);

  const aboutUs = useSelector((state) => state.status?.restaurant?.about_us);
  const address = useSelector((state) => state.status?.restaurant?.address);
  const city = useSelector((state) => state.status?.restaurant?.city);
  const state = useSelector((state) => state.status?.restaurant?.state);
  const country = useSelector((state) => state.status?.restaurant?.country);
  const zip = useSelector((state) => state.status?.restaurant?.zip);
  const email = useSelector((state) => state.status?.restaurant?.support_email);
  const phone = useSelector(
    (state) => state.status?.restaurant?.support_phone_number
  );
  const working_hours = useSelector(
    (state) => state.status?.restaurant?.working_hours
  );

  const newAddress =
    address + ", " + city + ", " + state + ", " + country + ", " + zip;
  // console.log(newAddress);

  useEffect(() => {
    getRestaurantDetails().then((res) => {
      if (res?.data) {
        setRestaurantDetails(res?.data);
        dispatch(restaurantDetailsAction(res?.data));
        // console.log(res?.data);

        // getSubCategory(res?.data.Token).then((res) => {
        //   const menSubCatArray = {};
        //   const womenSubCatArray = {};
        //   res?.data?.forEach((subCategory) => {
        //     if (
        //       subCategory?.category_for &&
        //       // subCategory?.category_for === "Men's"
        //       /(Men|men|Men's|men's|Mens|mens)/i.test(
        //         subCategory?.category_for
        //       )
        //     ) {
        //       menSubCatArray[subCategory?.sequence] = subCategory;
        //     }
        //     if (
        //       subCategory?.category_for &&
        //       // subCategory?.category_for === "Women's"
        //       /(Women|women|Women's|women's|Womens|womens)/i.test(
        //         subCategory?.category_for
        //       )
        //     ) {
        //       womenSubCatArray[subCategory?.sequence] = subCategory;
        //     }
        //   });
        //   const menSubCat = Object.values(menSubCatArray);
        //   const womenSubCat = Object.values(womenSubCatArray);
        //   setMenSubCategory(menSubCat);
        //   setWomenSubCategory(womenSubCat);
        //   console.log(menSubCatArray);
        //   console.log(womenSubCatArray);
        // });

        getSubCategory(res?.data.Token).then((res) => {
          const menSubCatArray = {};
          const womenSubCatArray = {};

          res?.data?.forEach((subCategory) => {
            const categoryFor = subCategory?.category_for?.toLowerCase();
            // console.log("Category for:", categoryFor);

            if (
              categoryFor === "men" ||
              categoryFor === "men's" ||
              categoryFor === "mens"
            ) {
              // console.log("Men category:", subCategory);
              menSubCatArray[subCategory.sequence] = subCategory;
            } else if (
              categoryFor === "women" ||
              categoryFor === "women's" ||
              categoryFor === "womens"
            ) {
              // console.log("Women category:", subCategory);
              womenSubCatArray[subCategory.sequence] = subCategory;
            }
          });

          const menSubCat = Object.values(menSubCatArray);
          const womenSubCat = Object.values(womenSubCatArray);

          setMenSubCategory(menSubCat);
          setWomenSubCategory(womenSubCat);

          // console.log("Men Subcategories:", menSubCat);
          // console.log("Women Subcategories:", womenSubCat);
        });
      }
    });

    // cartId && getCartItems(token, cartId).then((res) => {
    //   setCartItemList(res?.data.results);
    //   setCartCount(res?.data.count)
    //   console.log(res?.data.count);
    // });

    // console.log("location.pathname ===> ", location.pathname);
    // if (location?.pathname) {
    //   if (location.pathname === "/search") {
    //     setInputValue(inputValue);
    //   }
    // }

    if (cartVisible || mobileMenuVisible || searchBoxVisible) {
      document.addEventListener("click", handleOutsideClick, false);
    } else {
      document.removeEventListener("click", handleOutsideClick, false);
    }

    // Cleanup function to remove event listener when component unmounts
    return () => {
      document.removeEventListener("click", handleOutsideClick, false);
    };
  }, [cartVisible, mobileMenuVisible, searchBoxVisible]);

  useEffect(() => {
    if (location.pathname === `/search`) {
      setInputValue(inputValue);
    } else {
      setInputValue("");
    }
  }, [location.pathname]);
  // console.log(restaurant)

  const handleProducts = (item) => {
    // console.log(item);
    navigate(`/products`, { state: { subCategory: item } });
  };

  const handleOutsideClick = (e) => {
    if (!node.current.contains(e.target)) {
      setCartVisible(false);
      setInputValue("");
      setSearchBoxVisible(false);
    }
  };

  // useEffect(() => {
  //   if (cartVisible || mobileMenuVisible || searchBoxVisible) {
  //     document.addEventListener("click", handleOutsideClick, false);
  //   } else {
  //     document.removeEventListener("click", handleOutsideClick, false);
  //   }
  //   // Cleanup function to remove event listener when component unmounts
  //   return () => {
  //     document.removeEventListener("click", handleOutsideClick, false);
  //   };
  // }, [cartVisible, mobileMenuVisible, searchBoxVisible]);

  // const stopPropagation = (event) => {
  //   event.stopPropagation();
  //   setCartVisible((prevVisible) => !prevVisible);
  // };

  const handleItemDelete = (cart_item_id) => {
    deleteCartItem(token, cart_item_id).then((res) => {
      // console.log(cartId);
      cartId &&
        getCartItems(token, cartId).then((res) => {
          dispatch(userCartItems(res?.data));
          toast.error("Product removed from your cart!", {
            autoClose: 1500,
            style: {
              backgroundColor: "black",
              color: "white",
            },
            containerId: "site",
          });
        });
    });
  };

  const removePreLoginCartItemhandle = (color_id) => {
    dispatch(removePreLoginCartItem(color_id));
    toast.error("Product removed from your cart!", {
      autoClose: 1500,
      style: {
        backgroundColor: "black",
        color: "white",
      },
      containerId: "site",
    });
    // console.log(color_id);
  };

  const handleMobileMenu = async () => {
    setMobileMenuVisible((prevVisible) => !prevVisible);
    setMenMenuVisible(false);
    setWomenMenuVisible(false);
  };

  const handleMenMenu = async () => {
    setMenMenuVisible((prevVisible) => !prevVisible);
    setWomenMenuVisible(false);
  };

  const handleWomenMenu = () => {
    setWomenMenuVisible((prevVisible) => !prevVisible);
    setMenMenuVisible(false);
  };

  const clickOnMenu = () => {
    setMobileMenuVisible((prevVisible) => !prevVisible);
    setMenMenuVisible(false);
    setWomenMenuVisible(false);
  };

  const toggleCart = () => {
    setCartVisible((prevVisible) => !prevVisible);
  };
  // console.log("cartId ----====> ", cartId);

  /** Search Products */
  const handleSearchChange = (e) => {
    const searchKey = e?.target?.value;
    // console.log(searchKey);
    setInputValue(searchKey);
    getSearchProducts(token, searchKey).then((res) => {
      // console.log(res?.data?.data?.productSearch);
      setProductSuggestions(res?.data?.data?.productSearch);
    });
    // Filter suggestions based on input value
    const filteredSuggestions = productSuggestions?.filter(
      (suggestion) =>
        suggestion?.productName.toLowerCase().indexOf(searchKey.toLowerCase()) >
        -1
    );
    setFilteredSuggestions(filteredSuggestions);
    // console.log(filteredSuggestions);
    if (searchKey) {
      setInputValue(searchKey);
      setSearchBoxVisible(true);
    } else {
      setInputValue("");
      setSearchBoxVisible(false);
    }
  };

  const handleSearchProductSelect = (suggestion) => {
    setSearchBoxVisible(false);
    // console.log(suggestion.category);
    setInputValue(suggestion?.productName);
    navigate(`/search`, { state: { subCategory: suggestion?.category } });
  };

  // useEffect(() => {
  //   console.log(location.pathname);
  //   if (location?.pathname) {
  //     if (location.pathname === "/search") {
  //       setInputValue(inputValue);
  //     } else {
  //       setInputValue("");
  //     }
  //   } else {
  //     setInputValue("");
  //   }
  // }, [location?.pathname]);
  // /** /. Search Products */
  // console.log(filteredSuggestions);

  const handleViewCart = () => {
    setCartVisible(false);
  };

  const handleLogin = () => {
    toast.warning("Please sign in to access your cart.", {
      autoClose: 1500,
      style: {
        backgroundColor: "black",
        color: "white",
      },
      containerId: "site",
    });

    setTimeout(() => {
      navigate(`/login`);
    }, 3000);
  };

  const handleSearchClose = async () => {
    setInputValue("");
    setSearchBoxVisible(false);
  };

  const [coupons, setCoupons] = useState();
  const [isCouponValid, setIsCouponValid] = useState(false);

  const fetchCoupons = () => {
    getAllCoupons(token).then((res) => {
      // console.log(res?.data);
      setCoupons(res?.data);
      if (res?.data) {
        const currentDate = new Date();
        const couponExpiryDate = new Date(res?.data?.[0]?.expiry_date);

        // Set both dates to midnight to ignore time
        currentDate.setHours(0, 0, 0, 0);
        couponExpiryDate.setHours(0, 0, 0, 0);

        if (couponExpiryDate >= currentDate) {
          setIsCouponValid(true);
        } else {
          setIsCouponValid(false);
        }
      }
      // setIsLoading(false);
    });
  };

  // console.log(coupons);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchCoupons();
    // eslint-disable-next-line
  }, []);

  return (
    <div style={{ minHeight: "800px" }}>
      <ToastContainer
        position="top-center"
        className="mt-4 ToastContainer"
        limit={1}
        containerId="site"
      />

      {/* {coupons?.length > 0 ? coupons.map((item, i) => (<div key={i} className="top-banner align-items-center">
        <div ><strong>INAUGURAL OFFER {item.coupon_type === "Amount" ? currencySymbol : null}{item.coupon_type === "Percent" ? (item.percent_amount_off * 1).toFixed(0) : item.percent_amount_off}{item.coupon_type === "Percent" ? "%" : null} OFF ON LATEST COLLECTION</strong>
        </div>
        <div className="mt-2 fs-6">USED CODE - <span>{item.coupon_code}</span><a href="#">Shop Now</a></div>
      </div>)) : null} */}

      {/* <!-- header area start --> */}
      <header>
        {coupons?.length > 0 && isCouponValid ? (
          <div className="top-banner align-items-center">
            <div className="p-1" style={{ fontWeight: "600" }}>
              {/* INAUGURAL OFFER{" "}
            {coupons[0].coupon_type === "Amount" ? currencySymbol : null}
            {coupons[0].coupon_type === "Percent"
              ? (coupons[0].percent_amount_off * 1).toFixed(0)
              : coupons[0].percent_amount_off}
            {coupons[0].coupon_type === "Percent" ? "%" : null} OFF ON LATEST
            COLLECTION! */}
              <div
                dangerouslySetInnerHTML={{ __html: coupons[0].offer_text }}
              />

              {/* {(coupons[0].offer_text)} */}
            </div>
            <div className="" style={{ fontSize: "13.5px", fontWeight: "400" }}>
              Coupon Code : <span>{coupons[0].coupon_code}</span>
            </div>
            <div className="" style={{ fontSize: "15.5px", fontWeight: "500" }}>
              Hurry! Offer valid until :{" "}
              {new Date(coupons[0].expiry_date).toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            </div>
          </div>
        ) : null}
        {/* <!-- main menu area start --> */}
        <div className="header-main sticky bdr-bottom">
          <div className="container custom-container">
            <div className="row align-items-center position-relative">
              {/* col-md-6 col-6 */}
              <div className="col-lg-2 logo-web position-static">
                <div className="logo">
                  <Link to={`/`} className="logo1">
                    <img
                      className="logo-dimention ms-2"
                      src={logo} //"${PATH}/assets/img/bamboosa/logo.png"
                      alt="Brand logo"
                      // style={{height: "270px", width: "200px"}}
                    />
                  </Link>
                </div>
              </div>
              <div className="col-lg-12 logo-mobile col-md-12 col-12 position-static">
                <div className="logo text-center">
                  <Link to={`/`}>
                    <img
                      className="logo-dimention logo-mob-dimention"
                      src={logo} //"${PATH}/assets/img/bamboosa/logo.png"
                      alt="Brand logo"
                      // style={{height: "270px", width: "200px"}}
                    />
                  </Link>
                </div>
              </div>
              <div className="col-lg-10">
                <div className="row mb-1 margin-top d-flex justify-content-between">
                  <div className="col-md-2"></div>
                  <div className="col-md-6">
                    <div className="react-search-box web-search w-100">
                      <div className="search-wrap d-flex dropdown">
                        <button type="submit" className="search-trigger me-1">
                          <i className="ion-ios-search-strong"></i>
                        </button>
                        <input
                          type="text"
                          placeholder="Search..."
                          className="form-control transparent-background"
                          value={inputValue}
                          onChange={(e) => handleSearchChange(e)}
                          maxLength={50}
                        />

                        {inputValue ? (
                          <button
                            type="button"
                            className="search-trigger me-1"
                            onClick={() => handleSearchClose()}
                          >
                            <i className="ion-ios-close"></i>
                          </button>
                        ) : null}
                        {searchBoxVisible ? (
                          <div
                            className="dropdown-menu"
                            style={{
                              display:
                                filteredSuggestions?.length > 0
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <ul>
                              {filteredSuggestions?.map((suggestion, index) => (
                                <li
                                  key={index}
                                  onClick={() =>
                                    handleSearchProductSelect(suggestion)
                                  }
                                >
                                  <img
                                    src={suggestion.category.categoryUrl}
                                    alt="Product-Img"
                                    className="ms-4 me-3 prd-img"
                                  />
                                  {suggestion.productName}
                                </li>
                              ))}
                            </ul>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 custom-contact">
                    {/* <label className="me-5"><b>dipika.nasre@tissatech.com | +91 8989898989</b></label> */}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 d-none d-lg-block position-static flex-end main-menu-web">
                    <div className="main-header-inner">
                      <div className="main-menu">
                        <nav id="mobile-menu">
                          <ul className="d-flex justify-content-between">
                            <li>
                              <Link to={`/`} className="font-size">
                                Home
                              </Link>
                            </li>
                            <li>
                              <Link to="javascript:void(0)">
                                Men's <i className="fa fa-angle-down"></i>
                              </Link>
                              {menSubCategory && (
                                <ul className="dropdown">
                                  {menSubCategory?.map((item, i) => (
                                    <li
                                      key={i}
                                      onClick={() => handleProducts(item)}
                                    >
                                      <Link to="javascript:void(0)">
                                        {item.category}
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </li>
                            <li>
                              <Link to="javascript:void(0)">
                                Women's <i className="fa fa-angle-down"></i>
                              </Link>
                              {womenSubCategory && (
                                <ul className="dropdown">
                                  {womenSubCategory?.map((item, i) => (
                                    <li
                                      key={i}
                                      onClick={() => handleProducts(item)}
                                    >
                                      <Link to="javascript:void(0)">
                                        {item.category}
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </li>
                            {/* <li className="">
                              <Link to="javascript:void(0)">Products</Link>
                            </li> */}
                            <li>
                              <Link to={`/track-order`}>Track Your Order</Link>
                            </li>
                            <li>
                              <Link to={`/contact-us`}>Contact Us</Link>
                            </li>
                            <li>
                              <Link to={`/return-policy`}>Return Policy</Link>
                            </li>
                            <li>
                              <Link to={`/about-us`}>About Us</Link>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 ml-auto position-static">
                    <div className="header-setting-option setting-style-2">
                      <div
                        className="react-search-box mobile-search me-1 ms-1"
                        style={{ width: "67%" }}
                      >
                        <div className="search-wrap d-flex">
                          <button type="submit" className="search-trigger me-1">
                            <i className="ion-ios-search-strong"></i>
                          </button>
                          <input
                            type="text"
                            placeholder="Search..."
                            className="form-control transparent-background"
                            value={inputValue}
                            onChange={(e) => handleSearchChange(e)}
                            maxLength={50}
                            // onChange={(e) => setSearchTerm(e.target.value)}
                          />
                          {inputValue ? (
                            <button
                              type="button"
                              className="search-trigger me-1"
                              onClick={() => handleSearchClose()}
                            >
                              <i className="ion-ios-close"></i>
                            </button>
                          ) : null}
                          {searchBoxVisible ? (
                            <div
                              className="dropdown-menu width60"
                              style={{
                                display:
                                  filteredSuggestions?.length > 0
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <ul>
                                {filteredSuggestions?.map(
                                  (suggestion, index) => (
                                    <li
                                      key={index}
                                      onClick={() =>
                                        handleSearchProductSelect(suggestion)
                                      }
                                    >
                                      <img
                                        src={suggestion.productUrl}
                                        alt="Product-Img"
                                        className="ms-4 me-3 prd-img"
                                      />
                                      {suggestion.productName}
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div
                        className="user-account margin-start mt-2"
                        style={{ marginRight: "4px" }}
                      >
                        <div className="user-icon">
                          {Array.isArray(userData) ? (
                            <Link to={`/login`}>
                              {/* <i className="ion-ios-person-outline"></i> */}
                              <img
                                src={`${PATH}/assets/img/bamboosa/sign-in.png`}
                                className="mb-1 mtn-5"
                                style={{ padding: "0.02rem" }}
                                alt="question"
                              />
                              {/* <label>Sign In</label> */}
                            </Link>
                          ) : userData ? (
                            <Link to={`/my-account`}>
                              <img
                                src={`${PATH}/assets/img/bamboosa/user.png`}
                                className="p-1 me-3 mb-1 mtn-5"
                                alt="question"
                              />
                            </Link>
                          ) : (
                            <Link to={`/login`}>
                              <img
                                src={`${PATH}/assets/img/bamboosa/sign-in.png`}
                                className="mb-1 mtn-5"
                                style={{ padding: "0.02rem" }}
                                alt="question"
                              />
                              {/* <label>Sign In</label> */}
                            </Link>
                          )}
                        </div>
                      </div>
                      {/* { cartItemList ? */}
                      <div
                        className="header-mini-cart mt-2 margin-end"
                        ref={node}
                      >
                        <div
                          className="mini-cart-btn"
                          onClick={
                            cartCount || preLoginCount > 0
                              ? toggleCart
                              : toggleCart
                          }
                        >
                          {/* <i className="ion-bag"></i> */}
                          <img
                            src={`${PATH}/assets/img/bamboosa/bag.png`}
                            className="mb-1 mtn-5"
                            alt="question"
                            style={{ padding: "5px" }}
                          />

                          <span className="cart-notification">
                            {cartId
                              ? cartCount
                                ? cartCount
                                : 0
                              : preLoginCount > 0
                              ? preLoginCount
                              : 0}
                          </span>
                        </div>
                        {cartVisible && (
                          <ul
                            className={
                              cartVisible
                                ? "cart-list scroll-bar d-block"
                                : "cart-list scroll-bar d-none"
                            }
                          >
                            {cartItemList?.map((item, i) =>
                              item.color?.quantity > 0 ? (
                                <li key={i}>
                                  <div className="cart-img">
                                    <Link to="javascript:void(0)">
                                      <img
                                        src={
                                          item.color?.image_url?.split("@")[0]
                                        }
                                        className="w-100 h-100"
                                        alt="product-img"
                                      />
                                    </Link>
                                  </div>
                                  <div className="cart-info">
                                    <h4>
                                      <Link to="javascript:void(0)">
                                        {item.product.product_name} (
                                        {item.color?.color.split(",")[0]})
                                      </Link>
                                    </h4>
                                    <div className="d-flex justify-content-between">
                                      <div className="size">
                                        Size: {item?.color?.size.name}
                                      </div>
                                      {item.color?.quantity > 0 ? (
                                        <div className="size">
                                          Qty.: {item?.quantity}
                                        </div>
                                      ) : (
                                        <div className="size">
                                          <strong style={{ color: "red" }}>
                                            Out of Stock
                                          </strong>
                                        </div>
                                      )}
                                      {item.color?.quantity > 0 ? (
                                        <div className="size">
                                          {currencySymbol === "₹" ? "₹" : "$"}
                                          {(
                                            item.color?.price * 1
                                          ).toLocaleString("en-IN", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })}
                                        </div>
                                      ) : null}
                                    </div>
                                    {/* <div className="float-start">
                                      {item.color?.price ? <span>{currencySymbol === "₹" ? "Rs." : "$"} {item.color?.price} * {item?.quantity}</span> : null }
                                    </div> */}
                                    {/* <div className="float-end"> */}
                                    {item.color?.price &&
                                    item.color?.quantity > 0 ? (
                                      <span className="mt-0">
                                        {currencySymbol === "₹" ? "₹" : "$"}
                                        {(
                                          item.color?.price * item?.quantity
                                        ).toLocaleString("en-IN", {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })}
                                      </span>
                                    ) : null}
                                    {/* </div> */}
                                  </div>
                                  <div
                                    className="del-icon"
                                    onClick={() =>
                                      handleItemDelete(item.cart_item_id)
                                    }
                                  >
                                    <i
                                      className="fa fa-trash"
                                      style={{ color: "#ee122b" }}
                                    ></i>
                                  </div>
                                </li>
                              ) : null
                            )}
                            {cartItemList?.map((item, i) =>
                              item.color?.quantity < 1 ? (
                                <li key={i}>
                                  <div className="cart-img">
                                    <Link to="javascript:void(0)">
                                      <img
                                        src={
                                          item.color?.image_url?.split("@")[0]
                                        }
                                        className="w-100 h-100"
                                        alt="product-img"
                                      />
                                    </Link>
                                  </div>
                                  <div className="cart-info">
                                    <h4>
                                      <Link to="javascript:void(0)">
                                        {item.product.product_name} (
                                        {item.color?.color.split(",")[0]})
                                      </Link>
                                    </h4>
                                    <div className="d-flex justify-content-between">
                                      <div className="size">
                                        Size: {item?.color?.size.name}
                                      </div>
                                      {item.color?.quantity > 0 ? (
                                        <div className="size">
                                          Qty.: {item?.quantity}
                                        </div>
                                      ) : (
                                        <div className="size">
                                          <strong style={{ color: "red" }}>
                                            Out of Stock
                                          </strong>
                                        </div>
                                      )}
                                      {item.color?.quantity > 0 ? (
                                        <div className="size">
                                          {currencySymbol === "₹" ? "₹" : "$"}
                                          {(
                                            item.color?.price * 1
                                          ).toLocaleString("en-IN", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })}
                                        </div>
                                      ) : null}
                                    </div>
                                    {/* <div className="float-start">
                                      {item.color?.price ? <span>{currencySymbol === "₹" ? "Rs." : "$"} {item.color?.price} * {item?.quantity}</span> : null }
                                    </div> */}
                                    {/* <div className="float-end"> */}
                                    {item.color?.price &&
                                    item.color?.quantity > 0 ? (
                                      <span className="mt-0">
                                        {currencySymbol === "₹" ? "₹" : "$"}
                                        {(
                                          item.color?.price * item?.quantity
                                        ).toLocaleString("en-IN", {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })}
                                      </span>
                                    ) : null}
                                    {/* </div> */}
                                  </div>

                                  <div
                                    className="del-icon"
                                    onClick={() =>
                                      handleItemDelete(item.cart_item_id)
                                    }
                                  >
                                    <i
                                      className="fa fa-trash"
                                      style={{ color: "#ee122b" }}
                                    ></i>
                                  </div>
                                </li>
                              ) : null
                            )}
                            {cartId
                              ? null
                              : preLoginCount > 0
                              ? preLoginCart.map((item, i) => (
                                  <li key={i}>
                                    <div className="cart-img">
                                      <Link to="javascript:void(0)">
                                        <img
                                          src={item.image_url?.split("@")[0]}
                                          className="w-100 h-100"
                                          alt="product-img"
                                        />
                                      </Link>
                                    </div>
                                    <div className="cart-info">
                                      <h4>
                                        <Link to="javascript:void(0)">
                                          {item.product_name} (
                                          {item.color.split(",")[0]})
                                        </Link>
                                      </h4>
                                      <div className="d-flex justify-content-between">
                                        <div className="size">
                                          Size: {item.size}
                                        </div>

                                        <div className="size">
                                          Qty.: {item?.quantity}
                                        </div>

                                        <div className="size">
                                          {currencySymbol === "₹" ? "₹" : "$"}
                                          {(item.price * 1).toLocaleString(
                                            "en-IN",
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )}
                                        </div>
                                      </div>
                                      {/* <div className="float-start">
                                  {item.color?.price ? <span>{currencySymbol === "₹" ? "Rs." : "$"} {item.color?.price} * {item?.quantity}</span> : null }
                                </div> */}
                                      {/* <div className="float-end"> */}

                                      <span className="mt-0">
                                        {currencySymbol === "₹" ? "₹" : "$"}
                                        {(
                                          item.price * item?.quantity
                                        ).toLocaleString("en-IN", {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })}
                                      </span>

                                      {/* </div> */}
                                    </div>
                                    <div
                                      className="del-icon"
                                      onClick={() =>
                                        removePreLoginCartItemhandle(
                                          item.color_id
                                        )
                                      }
                                    >
                                      <i
                                        className="fa fa-trash"
                                        style={{ color: "#ee122b" }}
                                      ></i>
                                    </div>
                                  </li>
                                ))
                              : null}
                            {cartCount > 0 || preLoginCount > 0 ? (
                              <li className="checkout-btn sticky-btn">
                                {cartItemList ? (
                                  <Link
                                    to={`/cart`}
                                    onClick={() => handleViewCart()}
                                  >
                                    View Cart
                                  </Link>
                                ) : (
                                  <Link
                                    to="javascript:void(0)"
                                    onClick={() => {
                                      handleViewCart();
                                      handleLogin();
                                    }}
                                  >
                                    View Cart
                                  </Link>
                                )}
                              </li>
                            ) : (
                              <li>
                                <strong>Your cart has no items to show!</strong>
                                <button
                                  type="button"
                                  onClick={toggleCart}
                                  className="close"
                                  style={{
                                    fontSize: "1.5rem",
                                    opacity: "0.7",
                                    position: "absolute",
                                    top: "0",
                                    right: "0",
                                    fontWeight: "600",
                                  }}
                                >
                                  &times;
                                </button>
                              </li>
                            )}
                          </ul>
                        )}
                      </div>
                      {/* : null } */}
                    </div>
                  </div>
                  <div className="mobile-menu mean-container ms-1 mt-0 me-5">
                    <div className="mean-bar me-5">
                      <Link
                        to="javascript:void(0)"
                        className={
                          mobileMenuVisible
                            ? "meanmenu-reveal meanclose closebtn"
                            : "meanmenu-reveal meanclose closebtn d-none"
                        }
                        onClick={() => handleMobileMenu()}
                      >
                        <img
                          src={`${PATH}/assets/img/icon/cross.png`}
                          alt="cross"
                        />
                      </Link>
                      <Link
                        to="javascript:void(0)"
                        className={
                          mobileMenuVisible
                            ? "meanmenu-reveal d-none"
                            : " meanmenu-reveal mtn-5"
                        }
                        onClick={() => handleMobileMenu()}
                      >
                        <span></span>
                        <span></span>
                        <span></span>
                      </Link>
                      <nav
                        className={
                          mobileMenuVisible === true
                            ? "mean-nav d-block "
                            : "mean-nav d-none"
                        }
                      >
                        <ul>
                          <li className="active">
                            <Link to={`/`} onClick={() => clickOnMenu()}>
                              Home
                            </Link>
                          </li>
                          <li className="static">
                            <Link
                              to="javascript:void(0)"
                              onClick={() => handleMenMenu()}
                            >
                              Men's <i className="fa fa-angle-down"></i>
                            </Link>
                            {menSubCategory && (
                              <>
                                <ul
                                  className={
                                    menMenuVisible === true
                                      ? "megamenu dropdown d-block"
                                      : "megamenu dropdown d-none"
                                  }
                                >
                                  {menSubCategory?.map((item, i) => (
                                    <li
                                      className="mega-title"
                                      key={i}
                                      onClick={() => handleProducts(item)}
                                    >
                                      <Link
                                        to="javascript:void(0)"
                                        onClick={() => clickOnMenu()}
                                      >
                                        {item.category}
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                                <Link
                                  className="mean-expand"
                                  to="javascript:void(0)"
                                  onClick={() => handleMenMenu()}
                                >
                                  +
                                </Link>
                              </>
                            )}
                          </li>
                          <li className="static">
                            <Link
                              to="javascript:void(0)"
                              onClick={() => handleWomenMenu()}
                            >
                              Women's <i className="fa fa-angle-down"></i>
                            </Link>
                            {womenSubCategory && (
                              <>
                                <ul
                                  className={
                                    womenMenuVisible === true
                                      ? "megamenu dropdown d-block"
                                      : "megamenu dropdown d-none"
                                  }
                                >
                                  {womenSubCategory?.map((item, i) => (
                                    <li
                                      className="mega-title"
                                      key={i}
                                      onClick={() => handleProducts(item)}
                                    >
                                      <Link
                                        to="javascript:void(0)"
                                        onClick={() => clickOnMenu()}
                                      >
                                        {item.category}
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                                <Link
                                  className="mean-expand"
                                  to="javascript:void(0)"
                                  onClick={() => handleWomenMenu()}
                                >
                                  +
                                </Link>
                              </>
                            )}
                          </li>
                          <li className="">
                            <Link
                              to={`/track-order`}
                              onClick={() => clickOnMenu()}
                            >
                              Track Your Order
                            </Link>
                          </li>
                          <li className="">
                            <Link
                              to={`/contact-us`}
                              onClick={() => clickOnMenu()}
                            >
                              Contact
                            </Link>
                          </li>
                          <li className="">
                            <Link
                              to={`/return-policy`}
                              onClick={() => clickOnMenu()}
                            >
                              Return Policy
                            </Link>
                          </li>
                          <li className="">
                            <Link
                              to={`/about-us`}
                              onClick={() => clickOnMenu()}
                            >
                              About Us
                            </Link>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-12 d-block d-lg-none">
                <div className="mobile-menu"></div>
              </div> */}
            </div>
            {/* <div className="coupon-bar"></div> */}
          </div>
        </div>
        {/* <!-- main menu area end --> */}

        {/* <!-- Start Search Popup --> */}
        <div className="box-search-content search_active block-bg close__top">
          <form className="minisearch" action="#">
            <div className="field__search">
              <input type="text" placeholder="Search Our Catalog" />
              <div className="action">
                <Link to="javascript:void(0)">
                  <i className="fa fa-search"></i>
                </Link>
              </div>
            </div>
          </form>
          <div className="close__wrap">
            <span>close</span>
          </div>
        </div>
        {/* <!-- End Search Popup --> */}
      </header>
      {/* <!-- header area end --> */}

      <Outlet />

      {/* <!-- footer area start --> */}
      <footer>
        {/* <!-- footer widget area start --> */}
        <div className="footer-widget-area gray-bg bdr-top section-padding">
          <div className="container custom-container">
            <div className="row mtn-40 px-4">
              <div className="col-xl-4 col-lg-4 col-md-4">
                {/* <!-- footer single widget start --> */}
                <div className="footer-single-widget mt-40 justify-content d-flex">
                  <div>
                    <h5 className="widget-title">Information</h5>
                    <div className="widget-body">
                      <ul className="useful-links">
                        <li>
                          <Link to={`/about-us`}>About Us</Link>
                        </li>
                        <li>
                          <Link to={`/return-policy`}>Return Policy</Link>
                        </li>
                        <li>
                          <Link to={`/shipping-policy`}>Shipping Policy</Link>
                        </li>
                        <li>
                          <Link to={`/terms-and-conditions`}>
                            Terms & Conditions
                          </Link>
                        </li>
                        <li>
                          <Link to={`/privacy-policy`}>Privacy Policy</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* <!-- footer single widget start --> */}
              </div>

              <div className="col-xl-4 col-lg-4 col-md-4">
                {/* <!-- footer single widget start --> */}
                <div className="footer-single-widget mt-40 justify-content d-flex">
                  <div>
                    <h5 className="widget-title">Contact Us</h5>
                    <div className="widget-body">
                      <div className="d-flex">
                        <img
                          src={`${PATH}/assets/img/bamboosa/home.png`}
                          className="width-img-footer me-2"
                          alt="home"
                        />
                        <p className="desc" style={{ marginBottom: "7px" }}>
                          {newAddress}
                        </p>
                      </div>
                      <ul className="contact-info">
                        <li>
                          <img
                            src={`${PATH}/assets/img/bamboosa/mobile.png`}
                            className="width-img-footer me-2"
                            alt="home"
                          />
                          <Link to={`tel:${phone}`}>{phone}</Link>
                        </li>
                        <li>
                          <img
                            src={`${PATH}/assets/img/bamboosa/email.png`}
                            className="width-img-footer me-2"
                            alt="home"
                          />
                          <Link to={`mailto:${email}`}>{email}</Link>
                        </li>
                      </ul>
                    </div>
                    <div className="mt-5 web-bamboosa">
                      <div
                        className="row p-3"
                        style={{ background: "#E8E9EB" }}
                      >
                        <div className="d-flex justify-content-center mb-2">
                          <label
                            style={{
                              color: "#ee122b",
                              fontSize: "15px",
                              fontWeight: "700",
                            }}
                          >
                            Get the App for BambooSA!
                          </label>
                        </div>
                        <div className="col-6">
                          <Link
                            to="https://play.google.com/store/apps/details?id=com.bamboosa"
                            target="_blank"
                          >
                            <img
                              width="95%"
                              src={`${PATH}/assets/img/bamboosa/play_badge.png`}
                              alt="png"
                            />
                          </Link>
                        </div>
                        <div className="col-6">
                          <Link
                            to="https://apps.apple.com/us/app/bamboosa/id6502903278"
                            target="_blank"
                          >
                            <img
                              width="95%"
                              src={`${PATH}/assets/img/bamboosa/app_store.png`}
                              alt="png"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- footer single widget start --> */}
              </div>

              <div className="col-xl-4 col-lg-4 col-md-4">
                {/* <!-- footer single widget start --> */}
                <div className="footer-single-widget mt-40 justify-content d-flex">
                  <div>
                    <h5 className="widget-title">Social Links</h5>
                    <div className="widget-body">
                      <ul className="contact-info">
                        <li className="mb-3">
                          <Link
                            to="https://www.facebook.com/profile.php?id=61559649682837&is_tour_dismissed"
                            target="_blank"
                          >
                            <i
                              className="fa fa-facebook me-2"
                              style={{ color: "green", fontSize: "17px" }}
                            ></i>{" "}
                            Facebook
                          </Link>
                        </li>
                        <li className="mb-3">
                          <Link
                            to="https://www.instagram.com/bamboosalifestyle/?next=%2F"
                            target="_blank"
                          >
                            <i
                              className="fa fa-instagram me-1"
                              style={{ color: "green", fontSize: "17px" }}
                            ></i>{" "}
                            Instagram
                          </Link>
                        </li>
                        {/* <li>
                          <Link
                            to="https://www.linkedin.com/in/bamboosa-pvtltd-11a281309/"
                            target="_blank"
                          >
                            <i
                              className="fa fa-linkedin me-1"
                              style={{ color: "green", fontSize: "17px" }}
                            ></i>{" "}
                            LinkedIn
                          </Link>
                        </li> */}
                      </ul>
                    </div>

                    <div className="mt-5 mobile-bamboosa d-none">
                      <div
                        className="row p-3"
                        style={{ background: "#E8E9EB" }}
                      >
                        <div className="d-flex justify-content-center mb-2">
                          <label
                            style={{
                              color: "#ee122b",
                              fontSize: "15px",
                              fontWeight: "700",
                            }}
                          >
                            Get the App for BambooSA!
                          </label>
                        </div>
                        <div className="col-6">
                          <Link
                            to="https://play.google.com/store/apps/details?id=com.bamboosa"
                            target="_blank"
                          >
                            <img
                              width="95%"
                              src={`${PATH}/assets/img/bamboosa/play_badge.png`}
                              alt="png"
                            />
                          </Link>
                        </div>
                        <div className="col-6">
                          <Link
                            to="https://apps.apple.com/us/app/bamboosa/id6502903278"
                            target="_blank"
                          >
                            <img
                              width="95%"
                              src={`${PATH}/assets/img/bamboosa/app_store.png`}
                              alt="png"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- footer single widget start --> */}
              </div>
            </div>
          </div>
        </div>
        {/* <!-- footer widget area end --> */}

        {/* <!-- footer botton area start --> */}
        {/* <div className="footer-bottom-area">
          <div className="container custom-container">
            <div className="row align-items-center">
              
              <div className="col-md-6 order-3 order-md-2">
                <div className="copyright-text text-left">
                  <p className="ms-2">
                    &copy; Copyright{" "}
                    <Link to="javascript:void(0)">
                      <b>BAMBOOSA</b>
                    </Link>
                    . All Right Reserved{" "}
                    <Link to="javascript:void(0)">
                      <b>2024</b>
                    </Link>
                  </p>
                </div>
              </div>
              <div className="col-lg-6 ml-auto order-2 order-md-3">
                <div className="copyright-text">
                <p className="me-2 float-end">
                Powered by InstaApp Platform
                  </p>
                </div>
              
              </div>
              
            </div>
          </div>
        </div> */}

        {/* <!-- footer botton area start --> */}
        <div className="footer-bottom-area">
          <div className="container custom-container">
            <div className="row align-items-center">
              <div className="col-lg-6 order-1">
                <div className="copyright-text text-center">
                  <p className="p-1">
                    Copyright &copy; 2024{" "}
                    <b>BambooSA Agro Innovations(OPC) Private Limited</b>. All
                    Rights Reserved
                  </p>
                </div>
              </div>
              <div className="col-lg-3 order-3 order-md-2">
                {/* <div className="copyright-text text-center">
                  <p>
                    &copy; Copyright{" "}
                    <Link to="#"
                      <b>BAMBOOSA</b>
                    </Link>
                    . All Right Reserved{" "}
                    <Link to="#">
                      <b>2024</b>
                    </Link>
                  </p>
                </div> */}
              </div>
              <div className="col-lg-3 ml-auto order-2 order-md-3">
                <div className="copyright-text text-center">
                  {/* <img src="${PATH}/assets/img/payment.png" alt="" /> */}
                  <p className="p-1">Powered by InstaApp Platform</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- footer botton area end --> */}
        {/* <!-- footer botton area end --> */}
      </footer>
      {/* <!-- footer area end --> */}
    </div>
  );
}
