import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import IntlTelInput from "react-intl-tel-input";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getProfileDetails,
  updateProfileDetails,
} from "../../../services/UserLogin.service";
import { useSelector } from "react-redux";

export default function Profile(props) {
  const [countryCode, setCountryCode] = useState("");
  const [mobileNumber, setMobileNumber] = useState(null);
  const [profileData, setProfileData] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);

  let intlTelInputRef = useRef(null);
  const actTab = props.activeTab;
  const token = useSelector((state) => state.status.restaurant.Token);
  const customerId = useSelector((state) => state?.user?.user?.token?.id);
  const currencySymbol = useSelector(
    (state) => state.status.restaurant.currency_symbol
  );

  const {
    register,
    handleSubmit: handleSubmitProfile,
    setError,
    setValue,
    clearErrors,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const inputMobileNumber = () => {
    const mobileNumberPattern = /^[0-9]{10}$/;
    const mobileNumber = intlTelInputRef.current?.state.value;
    const code = intlTelInputRef.current?.state.title.split(" ")[2];

    if (mobileNumberPattern.test(mobileNumber)) {
      clearErrors("mobileNumber1");
      setValue("mobileNumber1", code + mobileNumber);
      setMobileNumber(code + mobileNumber);
    } else {
      setError("mobileNumber1", {
        type: "manual",
        message: "Enter Valid Mobile Number",
      });
    }

    if (!mobileNumber) {
      setError("mobileNumber1", {
        type: "manual",
        message: "Enter Mobile Number",
      });
    }
    // console.log("Mobile Number", code + mobileNumber);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    //Get Profile data
    getProfileDetails(token, customerId).then((res) => {
      setProfileData(res?.data?.results[0]);
      // console.log(res?.data?.results[0])
      // Set input values
      setValue("salutation", res?.data?.results[0]?.salutation);
      setValue("firstName", res?.data?.results[0]?.customer?.first_name);
      setValue("lastName", res?.data?.results[0]?.customer?.last_name);
      setValue("email", res?.data?.results[0]?.customer?.email);
      setMobileNumber(res?.data?.results[0]?.phone_number);
      // setPhoneNumber("mobileNumber1", res?.data?.results[0]?.phone_number);
      // console.log(res?.data?.results[0]?.phone_number);
      currencySymbol === "₹"
        ? setPhoneNumber(res?.data?.results[0]?.phone_number.split("+91")[1])
        : setPhoneNumber(res?.data?.results[0]?.phone_number.split("+1")[1]);
      if (intlTelInputRef.current && phoneNumber) {
        setValue("mobileNumber1", mobileNumber);
        intlTelInputRef.current.setNumber(phoneNumber);

        const code = intlTelInputRef.current?.state.title.split(" ")[2];

        //   const phone = getCountryCode(phoneNumber);
        //   setValue("mobileNumber1", code + phone.withoutCountryCode);
        //   setMobileNumber(mobileNumber);
      }
    });
  }, [phoneNumber]);

  // function getCountryCode( input ) {
  //   // Set default country code to US if no real country code is specified
  //   const defaultCountryCode = input.substr( 0, 1 ) !== '+' ? 'US' : null;
  //   let formatted = mobileNumber //new libphonenumber.asYouType( defaultCountryCode ).input( input );
  //   let countryCode = '';
  //   let withoutCountryCode = formatted;

  //   if ( defaultCountryCode === 'US' ) {
  //     countryCode = '+1';
  //     formatted = '+1 ' + formatted;
  //   }
  //   else {
  //     const parts = formatted.split( ' ' );
  //     countryCode = parts.length > 1 ? parts.shift() : '';
  //     withoutCountryCode = parts.join( ' ' );
  //   }
  //   console.log(withoutCountryCode)

  //   return {
  //     formatted,
  //     withoutCountryCode,
  //     countryCode,
  //   }
  // }

  const updateProfile = (item) => {
    // console.log(item);
    // const mobileNumber = item.mobileNumber1
    const data = {
      salutation: item.salutation,
      username: item.email,
      email: item.email,
      first_name: item.firstName,
      last_name: item.lastName,
      phone_number: mobileNumber,
    };
    // console.log(data);
    updateProfileDetails(token, customerId, data).then((res) => {
      // console.log(res);
      if (res?.data?.status === "error") {
        toast.error(res?.data?.message, {
          autoClose: 1500,
          style: {
            backgroundColor: "black",
            color: "white",
          },
          containerId: "profile",
        });
      } else {
        toast.success("Profile updated successfully!", {
          autoClose: 1500,
          style: {
            backgroundColor: "black",
            color: "white",
          },
          containerId: "profile",
        });
      }
    });
  };

  // console.log(phoneNumber, mobileNumber);

  return (
    <div
      style={{ minHeight: "800px" }}
      className="tab-content"
      id="myaccountContent"
    >
      <ToastContainer
        position="top-center"
        className="mt-4 ToastContainer"
        limit={1}
        containerId="profile"
      />

      <div
        className={`tab-pane fade ${actTab == "profileTab" ? "show active" : ""
          }`}
        id="address-edit"
        role="tabpanel"
        aria-labelledby="address-edit-tab"
      >
        <div className="myaccount-content mb-2">
          <h3>Profile</h3>
          <div className="account-details-form">
            <form
              action="#"
              method="post"
              onSubmit={handleSubmitProfile(updateProfile)}
            >
              <div className="row">
                <div className="col-lg-2">
                  <div className="single-input-item mx-2">
                    <label className="required">Salutation</label>
                    <select
                      className="form-select"
                      defaultValue=""
                      {...register("salutation", {
                        required: "Enter Salutation",
                      })}
                    >
                      <option value="" disabled>
                        Salutation
                      </option>
                      <option
                        value="Mr."
                        selected={profileData?.salutation === "Mr."}
                      >
                        Mr.
                      </option>
                      <option
                        value="Mrs."
                        selected={profileData?.salutation === "Mrs."}
                      >
                        Mrs.
                      </option>
                      <option
                        value="Miss."
                        selected={profileData?.salutation === "Miss."}
                      >
                        Miss.
                      </option>
                      <option
                        value="Ms."
                        selected={profileData?.salutation === "Ms."}
                      >
                        Ms.
                      </option>
                      <option
                        value="Other"
                        selected={profileData?.salutation === "Other"}
                      >
                        Other
                      </option>
                    </select>
                    {errors.salutation && (
                      <p className="error">{errors.salutation.message}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="single-input-item mx-2">
                    <label className="required">First Name</label>
                    <input
                      type="text"
                      placeholder="First Name"
                      {...register("firstName", {
                        required: "Enter First Name",
                      })}
                      maxLength={20}
                    />
                    {errors.firstName && (
                      <p className="error">{errors.firstName.message}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="single-input-item mx-2">
                    <label className="required">Last Name</label>
                    <input
                      type="text"
                      placeholder="Last Name"
                      {...register("lastName", {
                        required: "Enter Last Name",
                      })}
                      maxLength={20}
                    />
                    {errors.lastName && (
                      <p className="error">{errors.lastName.message}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="single-input-item mx-2">
                    <label className="required">Email Address</label>
                    <input
                      type="email"
                      placeholder="Email Address"
                      {...register("email", {
                        required: "Enter Email",
                        pattern: {
                          value:
                            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                          message: "Invalid Email Address",
                        },
                      })}
                      readOnly
                    />
                    {errors.email && (
                      <p className="error">{errors.email.message}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="single-input-item mx-2">
                    <label className="required">Mobile Number</label>
                    <IntlTelInput
                      {...register("mobileNumber1", {
                        required: "Enter Mobile Number",
                      })}
                      ref={intlTelInputRef}
                      containerClassName="intl-tel-input"
                      inputClassName="form-control"
                      defaultCountry={currencySymbol === "₹" ? "in" : "us"}
                      // defaultValue={phoneNumber}
                      autoPlaceholder=""
                      placeholder="Mobile Number"
                      value={
                        phoneNumber
                          ? phoneNumber
                          : mobileNumber
                            ? mobileNumber
                            : ""
                      }
                      onlyCountries={["in", "us"]}
                      onPhoneNumberChange={() => inputMobileNumber()}
                      // onCountryChange={() => handleCountryChange()}
                      separateDialCode={true}
                    />
                    {errors.mobileNumber1 && (
                      <p className="error">{errors.mobileNumber1.message}</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="single-input-item mt-5 mx-2">
                <button type="submit" className="check-btn sqr-btn ">
                  Update Profile
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
