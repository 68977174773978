import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  createCancelOrder,
  getOrderDetails,
  getPaymentDetails,
  markProductUnavailable,
  saveInvoicePdf,
} from "../../../services/Order.service";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";
import "jspdf-autotable";
import html2pdf from "html2pdf.js";
import StarRatings from "react-star-ratings";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { PATH, RESTAURANT_ID } from "../../../redux/constants/Constants";
import {
  addUserProductRating,
  updateUserProductRating,
} from "../../../services/Products.service";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function OrderDetails(props) {
  const actTab = props.activeTab;
  // console.log(actTab);

  const token = useSelector((state) => state?.status?.restaurant?.Token);
  const currencySymbol = useSelector(
    (state) => state?.status?.restaurant.currency_symbol
  );
  const logo = useSelector((state) => state.status?.restaurant?.compony_image);
  const aboutUs = useSelector((state) => state.status.restaurant.about_us);
  const address = useSelector((state) => state.status.restaurant.address);
  const city = useSelector((state) => state.status.restaurant.city);
  const state = useSelector((state) => state.status.restaurant.state);
  const country = useSelector((state) => state.status.restaurant.country);
  const zip = useSelector((state) => state.status.restaurant.zip);
  const email = useSelector((state) => state.status.restaurant.support_email);
  const phone = useSelector(
    (state) => state.status.restaurant.support_phone_number
  );
  const working_hours = useSelector(
    (state) => state.status.restaurant.working_hours
  );

  const newAddress =
    address + ", " + city + ", " + state + ", " + country + ", " + zip;
  // console.log(newAddress);
  const customerId = useSelector((state) => state?.user?.user?.token?.id);

  const navigate = useNavigate();
  const location = useLocation();
  const [orderID, setOrderID] = useState(null);
  const [orderDetails, setOrderDetails] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [orderDetailsData, setOrderDetailsData] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [transactionID, setTransactionID] = useState(null);
  const [review, setReview] = useState(null);
  const [reviewMessage, setReviewMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false); // State for loading indicator


  const fetchAPIs = () => {
    orderID &&
      getPaymentDetails(token, orderID).then((res) => {
        // console.log(res?.data);
        setPaymentDetails(res?.data);
      });

    orderID &&
      getOrderDetails(token, orderID).then((res) => {
        // console.log(res?.data);
        setOrderDetails(res?.data.results);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (location?.state?.orderDetails) {
      // console.log(location?.state?.orderDetails);
      setTransactionID(location?.state?.orderDetails.transaction_id);
      setOrderID(location.state.orderDetails.order.order_id);
      setOrderDetailsData(location.state.orderDetails);
      getPaymentDetails(token, location.state.orderDetails.order.order_id).then(
        (res) => {
          // console.log(res?.data);
          setPaymentDetails(res?.data);
        }
      );

      getOrderDetails(token, location.state.orderDetails.order.order_id).then(
        (res) => {
          // console.log(res?.data);
          setOrderDetails(res?.data.results);
        }
      );

      fetchAPIs();
    }
  }, []);

  const {
    register,
    handleSubmit: handleSubmitCancel,
    formState: { errors },
  } = useForm();

  const {
    register: register2,
    setValue: setValue2,
    clearErrors: clearErrors2,
    handleSubmit: handleSubmitRating,
    formState: { errors: errors2 },
  } = useForm();

  // console.log(orderDetails);
  // console.log(paymentDetails);
  // console.log(orderDetailsData);

  const handleCancelAllOrder = () => {
    const newSelectAll = !selectAll;
    const availableItems = orderDetails.filter(
      (item) => item.order_item_status === "Available" && item.quantity > 0
    );
    setSelectAll(newSelectAll);
    if (newSelectAll) {
      setSelectedItems(availableItems);
    } else {
      setSelectedItems([]);
    }
  };

  const handleCheckboxClick = (event, item) => {
    const availableItems = orderDetails.filter(
      (item) => item.order_item_status === "Available" && item.quantity > 0
    );
    // console.log(availableItems);
    if (event.target.checked) {
      setSelectedItems((prevItems) => [...prevItems, item]);
      if (
        availableItems?.length === selectedItems?.length + 1 &&
        selectedItems?.length >= 0
      ) {
        setSelectAll(true);
      } else {
        setSelectAll(false);
      }
      // console.log(availableItems?.length, selectedItems?.length + 1);
    } else {
      setSelectedItems((prevItems) => prevItems.filter((i) => i !== item));
      if (
        availableItems?.length === selectedItems?.length - 1 &&
        selectedItems?.length >= 0
      ) {
        setSelectAll(true);
      } else {
        setSelectAll(false);
      }
      // console.log(availableItems?.length, selectedItems?.length - 1);
    }
  };

  // console.log(orderDetails);

  const cancelOrder = async (item) => {
    setIsLoading(true);
    // console.log(selectedItems);
    document.getElementById("closeCancelModal").click();

    if (selectAll) {
      const data = {
        is_delivery_cancel: false,
        payment_id: orderDetailsData.payment_id,
        reason_for_cancellation: item.remark,
      };

      // console.log(data);

      createCancelOrder(token, data).then((res) => {
        // console.log(res?.data);
        toast.error(<React.Fragment>Order has been canceled!<br />Refund will be processed to your original payment method within 10 business days.</React.Fragment>, {
          autoClose: 6000,
          style: {
            backgroundColor: "black",
            color: "white",
          },
          containerId: "orderDetails",
        });
        setIsLoading(false);
        document.getElementById("closeCancelModal").click();
        setTimeout(() => {
          navigate(`/my-account/orders`, {
            state: { paymentSuccessful: false },
          });
        }, 1500);
      });
    } else {
      let allCanceled = true;

      for (const item of selectedItems) {
        const data = {
          order_id: item.order.toString(),
          company_id: item.product.restaurant.toString(),
        };

        try {
          const res = await markProductUnavailable(
            token,
            item.order_item_id.toString(),
            data
          );

          if (res && res.data) {
            // console.log(res);
          } else {
            allCanceled = false;
          }
        } catch (error) {
          allCanceled = false;
          console.error("Error marking product as unavailable:", error);
        }
      }

      if (allCanceled) {
        selectedItems.length > 1 ?
          toast.error(<React.Fragment>Products have been canceled!<br />Refund will be processed to your original payment method within 10 business days.</React.Fragment>, {
            autoClose: 6000,
            style: {
              backgroundColor: "black",
              color: "white",
            },
            containerId: "orderDetails",
          }) : toast.error(<React.Fragment>Product has been canceled!<br />Refund will be processed to your original payment method within 10 business days.</React.Fragment>, {
            autoClose: 6000,
            style: {
              backgroundColor: "black",
              color: "white",
            },
            containerId: "orderDetails",
          })
        setIsLoading(false);
      } else {
        toast.error("Some products could not be canceled. Please try again.", {
          autoClose: 1500,
          style: {
            backgroundColor: "black",
            color: "white",
          },
          containerId: "orderDetails",
        });
        setIsLoading(false);
      }

      // Clear the selected items array after processing
      setSelectedItems([]);
      fetchAPIs();
    }
    // console.log(item);
  };


  // const cancelOrder = async (item) => {
  //   console.log(selectedItems);
  //   document.getElementById("closeCancelModal").click();

  //   if (selectAll) {
  //     const data = {
  //       is_delivery_cancel: false,
  //       payment_id: orderDetailsData.payment_id,
  //       reason_for_cancellation: item.remark,
  //     };

  //     console.log(data);

  //     createCancelOrder(token, data).then((res) => {
  //       console.log(res?.data);
  //       toast.error("Order has been canceled!", {
  //         autoClose: 1500,
  //         style: {
  //           backgroundColor: "black",
  //           color: "white",
  //         },
  //         containerId: "orderDetails",
  //       });
  //       document.getElementById("closeCancelModal").click();
  //       setTimeout(() => {
  //         navigate(`/my-account/orders`, {
  //           state: { paymentSuccessful: false },
  //         });
  //       }, 1500);
  //       // fetchAPIs()
  //     });
  //   } else {
  //     console.log(selectedItems);
  //     // document.getElementById("closeCancelModal").click();

  //     console.log(selectedItems);
  //     for (const item of selectedItems) {
  //       const data = {
  //         order_id: item.order.toString(),
  //         company_id: item.product.restaurant.toString(),
  //       };

  //       try {
  //         const res = await markProductUnavailable(
  //           token,
  //           item.order_item_id.toString(),
  //           data
  //         );
  //         console.log(res);
  //         fetchAPIs();
  //       } catch (error) {
  //         console.error("Error marking product as unavailable:", error);
  //       }
  //     }

  //     // Clear the selected items array after processing
  //     setSelectedItems([]);
  //     document.getElementById("closeCancelModal").click();
  //     fetchAPIs();
  //   }
  //   console.log(item);
  // };

  const handleGeneratePdf = () => {
    const element = divRef.current;

    const img = new Image();
    img.src = logo;
    img.onload = () => {
      html2pdf(element, {
        margin: 0,
        filename: "document.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      });
    };
  };

  const [invoicePdf, setInvoicePdf] = useState(null);

  const divRef = useRef();

  const downloadPDF = () => {
    const element = document.getElementById("htmlToPrint");

    // Apply background color to the whole page
    element.style.backgroundColor = "#f0f9e7";

    const opt = {
      margin: 0,
      filename: `Invoice-${orderDetailsData?.order.invoice_id}`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2, backgroundColor: "#f0f9e7" },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    // Preload images before rendering
    const images = element.getElementsByTagName("img");
    const promises = [];
    for (let i = 0; i < images.length; i++) {
      const img = images[i];
      if (!img.complete) {
        const promise = new Promise((resolve, reject) => {
          img.onload = resolve;
          img.onerror = reject;
        });
        promises.push(promise);
      }
    }

    Promise.all(promises)
      .then(() => {
        // Generate the PDF
        html2pdf()
          .from(element)
          .set(opt)
          .outputPdf("blob")
          .then((pdfBlob) => {
            // Convert Blob to File
            const pdfFile = new File(
              [pdfBlob],
              `Invoice-${orderDetailsData?.order.invoice_id}.pdf`,
              { type: "application/pdf" }
            );

            // Save the PDF in the state object
            setInvoicePdf(pdfFile);

            // Create FormData object
            const formData = new FormData();
            formData.append("invoice", pdfFile);

            // Send the PDF to the backend
            paymentDetails &&
              saveInvoicePdf(token, formData, paymentDetails.order_id).then(
                (res) => {
                  // console.log(res);
                }
              );

            // Optionally download the PDF
            html2pdf().from(element).set(opt).save();
          });
      })
      .catch((error) => {
        console.error("Error loading images:", error);
      })
      .finally(() => {
        // Remove the background color style after PDF generation
        element.style.backgroundColor = "";
      });
  };

  // console.log(invoicePdf);

  const [ratings, setRatings] = useState({});

  // console.log(ratings);

  // Catch Rating value
  const handleRating = (item, rating) => {
    const id = item.order_item_id;
    // console.log(id, rating);
    setRatings((prevRatings) => ({
      ...prevRatings,
      [id]: rating,
    }));

    // console.log(item);

    const data = {
      product_id: item?.product.product_id,
      customer_id: customerId,
      restaurant: RESTAURANT_ID,
      color: item?.color.color_id,
      order_item_id: item.order_item_id,
      rating: rating,
      review_message: null,
      image: null,
    };

    if (item?.review?.length > 0) {
      data.image = item.review[0].image_url ? item.review[0].image_url : null;
      data.review_message = item.review[0].review_message;
      const reviewID = item.review[0].review_id;

      // console.log(data);

      // console.log(reviewID);
      updateUserProductRating(token, data, reviewID).then((res) => {
        // console.log(res?.data);
        res?.data && fetchAPIs();
        res?.data &&
          toast.success("Thank you for your rating!", {
            autoClose: 1500,
            style: {
              backgroundColor: "black",
              color: "white",
            },
            containerId: "orderDetails",
          });
      });
    } else {
      addUserProductRating(token, data).then((res) => {
        // console.log(res?.data);
        res?.data && fetchAPIs();
        res?.data &&
          toast.success("Thank you for your rating!", {
            autoClose: 1500,
            style: {
              backgroundColor: "black",
              color: "white",
            },
            containerId: "orderDetails",
          });
      });
    }

    // console.log(data);
  };

  const [uploadImages, setUploadImages] = useState([]);

  const reviewOrder = (item) => {
    // console.log(item);
    // console.log(uploadImages);
    // console.log(review);
    const reviewID = review.review[0].review_id;

    const data = {
      product_id: review?.product.product_id,
      customer_id: customerId,
      restaurant: RESTAURANT_ID,
      color: review?.color.color_id,
      order_item_id: review?.order_item_id,
      rating: review?.review[0].rating,
      review_message: item.review,
      image: uploadImages,
    };

    // console.log(data);

    updateUserProductRating(token, data, reviewID).then((res) => {
      // console.log(res.data);
      if (res?.data) {
        setUploadImages([]);

        const displayedImagesElement =
          document.getElementById("displayedImagesAdd");
        if (displayedImagesElement !== null) {
          displayedImagesElement.innerHTML = "";
        }

        res?.data &&
          toast.success("Thank you for your review!", {
            autoClose: 1500,
            style: {
              backgroundColor: "black",
              color: "white",
            },
            containerId: "orderDetails",
          });
        document.getElementById("ratingOrderModal").click();
        fetchAPIs();
        setUploadImages([]);
        // document.getElementById("displayedImagesAdd").appendChild();
      }
    });
  };

  // const { register } = useFormContext();

  const addImage = (event) => {
    const input = event.target;
    if (input.files && input.files.length > 0) {
      const newImages = Array.from(input.files);

      // Update the state to include new images
      setUploadImages((prevImages) => [...prevImages, ...newImages]);

      // Optionally, you can display the uploaded images here
      newImages.forEach((file) => {
        const reader = new FileReader();
        reader.onload = function (e) {
          // Display the uploaded image
          const imgContainer = document.createElement("div");
          imgContainer.classList.add("img-container1");

          const img = document.createElement("img");
          img.src = e.target.result;
          img.classList.add("uploaded-image");

          // Add a remove button
          const removeButton = document.createElement("button");
          removeButton.innerText = "X";
          removeButton.classList.add("remove-button");
          removeButton.addEventListener("click", () => {
            imgContainer.remove();
            setUploadImages((prevImages) =>
              prevImages.filter((prevFile) => prevFile !== file)
            );
          });

          imgContainer.appendChild(img);
          imgContainer.appendChild(removeButton);
          document
            .getElementById("displayedImagesAdd")
            .appendChild(imgContainer);
        };
        reader.readAsDataURL(file);
      });

      // setError2("uploadImages", { type: "manual", message: "" });
    }
  };

  const handleAddImageClick = () => {
    // Trigger the click event on the file input when the displayed image is clicked
    document.getElementById("imageInputAdd").click();
  };

  const handleChange = (event) => {
    setReviewMessage(event.target.value);
    setValue2("review", event.target.value);
    clearErrors2();
  };

  const handleClear = (item) => {
    setReview(item);

    setReviewMessage(
      item.review?.[0]?.review_message &&
        item.review?.[0]?.review_message !== "null"
        ? item.review?.[0]?.review_message
        : null
    );

    setValue2(
      "review",
      item.review?.[0]?.review_message &&
        item.review?.[0]?.review_message !== "null"
        ? item.review?.[0]?.review_message
        : ""
    );

    clearErrors2();

    setUploadImages([]);

    const displayedImagesElement =
      document.getElementById("displayedImagesAdd");
    if (displayedImagesElement !== null) {
      displayedImagesElement.innerHTML = "";
    }
  };

  // console.log(orderDetails);
  // console.log(orderDetailsData);
  // console.log(paymentDetails);
  // console.log(uploadImages);

  return (
    <div style={{ minHeight: "800px" }}>
      <ToastContainer
        position="top-center"
        className="mt-4 ToastContainer"
        limit={1}
        containerId="orderDetails"
      />

      {/* invoice download */}
      <div className="d-none">
        {/* d-none */}
        <div id="htmlToPrint" ref={divRef}>
          <div className="body2">
            <div className="container2">
              <h4 className="fw-bold text-center">Invoice</h4>
              <div className="header">
                <img
                  src={`${PATH}/assets/img/bamboosa/bamboosa-logo.png`}
                  // src="https://storage.googleapis.com/restaurant60-dev-media/bamboosa-logo.png"
                  alt="Logo"
                  className="logo2"
                />
                <div className="order-info">
                  {/* <h2 className="fw-bold">Invoice</h2> */}
                  <div className="order-details">
                    <div>Order ID: #{orderDetailsData?.order.order_id}</div>
                    <div>
                      Invoice ID:{" "}
                      {orderDetailsData?.order?.invoice_id
                        ? orderDetailsData?.order?.invoice_id
                        : "INV-12345678-1a2b3456"}
                    </div>
                    <div>GST Number: 27AKMPD9694H1ZJ</div>
                    <div>
                      Date: {orderDetailsData?.created_at?.split("T")[0]}
                    </div>
                  </div>
                </div>
              </div>
              <div className="address-container">
                <div className="address-box">
                  <p className="strong">
                    <strong>Billing Address:</strong>
                  </p>
                  <p className="strong">
                    {
                      orderDetailsData?.order?.billing_address_text?.split(
                        ","
                      )[0]
                    }
                  </p>
                  <p>
                    {orderDetailsData?.order?.billing_address_text
                      ?.split(",")
                      .slice(1)
                      .join(", ")}
                  </p>
                </div>
                <div className="address-box">
                  <p className="strong">
                    <strong>Shipping Address:</strong>
                  </p>
                  <p className="strong">
                    {
                      orderDetailsData?.order?.shipping_address_text?.split(
                        ","
                      )[0]
                    }
                  </p>
                  <p>
                    {orderDetailsData?.order?.shipping_address_text
                      ?.split(",")
                      .slice(1)
                      .join(", ")}
                  </p>
                </div>
              </div>
              <table className="items-table">
                <thead>
                  <tr>
                    <th data-label="Product Name">Product Name</th>
                    <th data-label="Product ID">Product ID</th>
                    <th data-label="Color">Color</th>
                    <th data-label="Size">Size</th>
                    <th data-label="Price Including GST">
                      Price Including GST
                    </th>
                    <th data-label="Quantity">Quantity</th>
                    <th data-label="Gross Total">Gross Total</th>
                  </tr>
                </thead>
                <tbody>
                  {orderDetails &&
                    orderDetails.map(
                      (item, i) =>
                        item.order_item_status === "Available" && (
                          <tr key={i}>
                            <td data-label="Product Name">
                              {item.product.product_name}
                            </td>
                            <td data-label="Product ID">
                              {item.product.product_id}
                            </td>
                            <td data-label="Color">
                              {item.color.color.split(",")[0]}
                            </td>
                            <td data-label="Size">{item.product_size.size}</td>
                            <td data-label="Price with GST">
                              {currencySymbol}
                              {(
                                parseFloat(item.price).toFixed(2) * 1
                              ).toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </td>
                            <td data-label="Quantity">{item.quantity}</td>
                            <td data-label="Gross Total">
                              {currencySymbol}
                              {(
                                item.quantity *
                                parseFloat(item.price).toFixed(2)
                              ).toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </td>
                          </tr>
                        )
                    )}
                </tbody>
              </table>
              <div className="total-info">
                <table className="total-info-table">
                  <tr>
                    <th>Total MRP:</th>
                    <td>
                      {currencySymbol}
                      {Number(
                        orderDetails &&
                        orderDetails
                          .reduce((accum, item) => {
                            const mrp =
                              item.order_item_status === "Available" &&
                              parseFloat(item.color.MRP);
                            const quantity =
                              item.order_item_status === "Available" &&
                              item.quantity;
                            return accum + mrp * quantity;
                          }, 0)
                          .toFixed(2)
                      ).toLocaleString("en-IN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </td>
                  </tr>
                  <tr>
                    <th>Discount on MRP:</th>
                    <td>
                      - {currencySymbol}
                      {Number(
                        orderDetails &&
                        orderDetails
                          .reduce((accum, item) => {
                            const mrp =
                              item.order_item_status === "Available" &&
                              parseFloat(item.color.MRP);
                            const quantity =
                              item.order_item_status === "Available" &&
                              item.quantity;
                            const price =
                              item.order_item_status === "Available" &&
                              parseFloat(item.price);
                            // return accum + mrp * quantity - price * quantity;
                            return accum + mrp * quantity - price * quantity >
                              0
                              ? accum + mrp * quantity - price * quantity
                              : 0;
                          }, 0)
                          .toFixed(2)
                      ).toLocaleString("en-IN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </td>
                  </tr>
                  <tr>
                    <th>Additional Discount</th>
                    <td>
                      - {currencySymbol}
                      {(paymentDetails?.discount * 1).toLocaleString("en-IN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </td>
                  </tr>
                  {/* <tr>
                    <th>GST Number:</th>
                    <td>27AKMPD9694H1ZJ</td>
                  </tr> */}
                  <tr>
                    <th>GST (5%):</th>
                    <td>
                      {currencySymbol}
                      {Number(
                        orderDetails &&
                        orderDetails
                          .reduce((accum, item) => {
                            const quantity =
                              item.order_item_status === "Available" &&
                              item.quantity;
                            const price =
                              item.order_item_status === "Available" &&
                              parseFloat(item.price);
                            const totalAmount = price * quantity;
                            const gst = totalAmount * 0.05;
                            return accum + gst;
                          }, 0)
                          .toFixed(2)
                      ).toLocaleString("en-IN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </td>
                  </tr>
                  <tr>
                    <th>Delivery Charges</th>
                    <td style={{ color: "green" }}>Free</td>
                  </tr>
                  <tr>
                    <th>Total Amount:</th>
                    <td>
                      {currencySymbol}
                      {Number(
                        orderDetails &&
                        orderDetails
                          .reduce((accum, item) => {
                            const quantity =
                              item.order_item_status === "Available" &&
                              item.quantity;
                            const price =
                              item.order_item_status === "Available" &&
                              parseFloat(item.price);
                            return accum + price * quantity;
                          }, 0)
                          .toFixed(2) - paymentDetails?.discount
                      ).toLocaleString("en-IN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </td>
                  </tr>
                </table>
              </div>
              <div className="additional-info1">
                <p>Reg Address: {newAddress}</p>
              </div>
              <div className="footer">
                <p>
                  If you have any questions, feel free to call customer care at{" "}
                  {phone} or use the Contact Us section in our App, or log on to{" "}
                  <a href="https://bamboosa.online/">
                    https://bamboosa.online/
                  </a>
                </p>
                <p>email: {email}</p>
                <p>Thank you for your purchase!</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* end */}

      {/* Cancel Order Modal */}
      <div
        className="modal fade mt-5 ms-2"
        id="cancelOrderModal"
        tabindex="-1"
        aria-labelledby="cancelOrderModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog mt-5">
          <div className="modal-body">
            <div>
              <div className="login-reg-form-wrap">
                <div className="d-flex justify-content-between mb-0">
                  <h2>Warning</h2>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    id="closeCancelModal"
                  ></button>
                </div>

                <form
                  action="#"
                  method="post"
                  onSubmit={handleSubmitCancel(cancelOrder)}
                >
                  <div className="">
                    <label className="mb-3">
                      <b> Are you sure you want to Cancel this Order?</b>
                    </label>{" "}
                  </div>
                  <label>Add Remark</label>{" "}
                  <i className="text-danger">
                    <b>*</b>
                  </i>
                  <div className="single-input-item mb-5 mt-1">
                    <input
                      className="mt-2"
                      type="text"
                      placeholder="Enter Remark"
                      {...register("remark", {
                        required: "Enter Remark",
                      })}
                      maxLength={500}
                    />
                    {errors.remark && (
                      <p className="error">{errors.remark.message}</p>
                    )}
                  </div>
                  <div className="single-input-item">
                    <button className="sqr-btn w-100" type="submit">
                      Cancel Order
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end */}

      {/* Rating Order Modal */}
      <div
        className="modal fade mt-5 ms-2"
        id="ratingOrderModal"
        tabindex="-1"
        aria-labelledby="ratingOrderModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog mt-5">
          <div className="modal-body">
            <div>
              <div className="login-reg-form-wrap">
                <div className="d-flex justify-content-between mb-0">
                  <h2>Raview Product</h2>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    id="ratingCancelModal"
                  ></button>
                </div>

                <form
                  action="#"
                  method="post"
                  onSubmit={handleSubmitRating(reviewOrder)}
                >
                  <div className=""></div>
                  <label>Write a Review</label>{" "}
                  <i className="text-danger">
                    <b>*</b>
                  </i>
                  <div className="single-input-item mb-2 mt-1">
                    <textarea
                      className="mt-2"
                      type="text"
                      placeholder="Enter Review"
                      {...register2("review", {
                        required: "Enter Review",
                      })}
                      value={reviewMessage}
                      onChange={(event) => handleChange(event)}
                      maxLength={1000}
                    />
                    {errors2.review && (
                      <p className="error" style={{ marginTop: "-10px" }}>
                        {errors2.review.message}
                      </p>
                    )}
                  </div>
                  {review?.review?.[0]?.image_url ? (
                    <label>Photo</label>
                  ) : (
                    <label>Add Photo</label>
                  )}
                  <div className="product-modal2 row mb-2">
                    {review?.review?.[0]?.image_url ? null : (
                      <div className="col-2">
                        <input
                          type="file"
                          id="imageInputAdd"
                          accept="image/*"
                          multiple // Allow multiple file selection
                          {...register2("uploadImages")}
                          style={{ display: "none" }}
                          onChange={addImage}
                        />
                        {/* Displayed image */}
                        <img
                          src={`${PATH}/assets/img/bamboosa/upload.png`}
                          alt="Upload"
                          // className="w-100 h-100"
                          onClick={() => handleAddImageClick()}
                        />
                      </div>
                    )}

                    {review?.review?.[0]?.image_url ? (
                      <div id="displayedImagesAdd2" className="col-md-10">
                        {review?.review?.[0]?.image_url
                          .split("@")
                          .map((url, index) => (
                            <img key={index} src={url} alt={`img-${index}`} />
                          ))}
                      </div>
                    ) : (
                      <div id="displayedImagesAdd" className="col-md-10"></div>
                    )}
                  </div>
                  <div className="single-input-item">
                    <button className="sqr-btn w-100" type="submit">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end */}

      <div>
        <div className="tab-content" id="myaccountContent">
          <div
            className={`tab-pane fade ${actTab === "orderDetailsTab" ? "show active" : ""
              }`}
            id="orders"
            role="tabpanel"
            aria-labelledby="orders-tab"
          >
            <section className="mt-0" id="address-section">
              <div className="container">
                <div className="card px-4 py-2">
                  <div className="row">
                    <div className="col-md-6">
                      <table className="table-bill" style={{ width: "100%" }}>
                        <tbody>
                          <tr>
                            <th width="40%">Order ID</th>

                            <td width="2%">:</td>

                            <td
                              width="58%"
                              style={{ fontWeight: "bold", color: "red" }}
                            >
                              {" "}
                              #{orderDetailsData?.order.order_id}
                            </td>
                          </tr>

                          <tr>
                            <th width="40%">Status</th>

                            <td width="2%">:</td>

                            <td
                              width="58%"
                              style={{
                                fontWeight: "bold",
                                color:
                                  orderDetailsData?.status === "ORDER_PLACED"
                                    ? "orange"
                                    : orderDetailsData?.status === "Canceled"
                                      ? "red"
                                      : "green",
                              }}
                            >
                              {" "}
                              {orderDetailsData?.status
                                .replaceAll("_", " ")
                                .toUpperCase()}
                            </td>
                          </tr>
                          <tr>
                            <th width="40%">Order date</th>

                            <td width="2%">:</td>

                            <td width="58%">
                              {new Date(
                                orderDetailsData?.created_at
                              ).toLocaleString("en-US", {
                                day: "numeric",
                                month: "short",
                                year: "numeric",
                                // hour: "numeric",
                                // minute: "numeric",
                                // second: 'numeric',
                              })}
                            </td>
                          </tr>

                          <tr>
                            <th width="40%">Transaction ID</th>

                            <td width="2%">:</td>

                            <td width="58%"> {transactionID}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    {/* <div className="col-md-1"></div> */}
                    <div className="col-md-6">
                      <table className="table-bill">
                        <tbody>
                          <tr>
                            <th width="40%">Billing Address</th>

                            <td width="2%">:</td>

                            <td width="58%">
                              {orderDetailsData?.order?.billing_address_text}
                            </td>
                          </tr>

                          <tr>
                            <th width="40%">Shipping Address</th>

                            <td width="2%">:</td>

                            <td width="58%">
                              {orderDetailsData?.order?.shipping_address_text}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div className="myaccount-content">
              <div className="row">
                <div className="col-lg-12">
                  {/* <!-- Cart Table Area --> */}
                  <div className="cart-table table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th className="pro-thumbnail">
                            {orderDetailsData?.status === "ORDER_PLACED" ? (
                              <div className="form-check">
                                <input
                                  className="form-check-input p-2 mt-0 me-2"
                                  type="checkbox"
                                  value=""
                                  id="flexCheckDefault"
                                  onChange={handleCancelAllOrder}
                                  checked={selectAll}
                                />
                                Select All
                              </div>
                            ) : (
                              <div>#</div>
                            )}
                          </th>
                          <th className="pro-thumbnail">Thumbnail</th>
                          <th className="pro-title">Product</th>
                          <th>Size</th>
                          <th className="pro-price">Price</th>
                          <th className="pro-quantity">Quantity</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderDetails?.length > 0 &&
                          orderDetails?.map((item, i) =>
                            item.quantity > 0 ? (
                              orderDetailsData?.status === "ORDER_PLACED" ? (
                                <tr key={item.order_item_id}>
                                  <td className="pro-thumbnail">
                                    {orderDetailsData?.status ===
                                      "ORDER_PLACED" ? (
                                      <div className="form-check d-flex justify-content-center">
                                        {item.order_item_status ===
                                          "Unavailable" ? (
                                          <div
                                            style={{
                                              color: "red",
                                              marginLeft: "-15px",
                                            }}
                                          >
                                            CANCELED
                                          </div>
                                        ) : (
                                          <input
                                            className="form-check-input p-2"
                                            type="checkbox"
                                            value=""
                                            id="flexCheckDefault"
                                            checked={selectedItems.includes(
                                              item
                                            )}
                                            onChange={(event) =>
                                              handleCheckboxClick(event, item)
                                            }
                                          />
                                        )}
                                      </div>
                                    ) : (
                                      <div>{i + 1}</div>
                                    )}
                                  </td>
                                  <td className="pro-thumbnail">
                                    <img
                                      className="img-fluid w-75"
                                      src={item.color.image_url.split("@")[0]}
                                      alt="Product"
                                    />
                                  </td>
                                  <td className="pro-title">
                                    {item.product.product_name} (
                                    {item.color.color.split(",")[0]}){" "}
                                    {orderDetailsData?.status ===
                                      "DELIVERED" && <br />}
                                    {orderDetailsData?.status ===
                                      "DELIVERED" && (
                                        <StarRatings
                                          className="my-1"
                                          // onClick={(rating) => handleRating(item, rating)}
                                          rating={
                                            item.review?.[0]?.rating
                                              ? item.review?.[0]?.rating
                                              : 0
                                          }
                                          starRatedColor="#ffdf00"
                                          changeRating={(rating) =>
                                            handleRating(item, rating)
                                          }
                                          numberOfStars={5}
                                          name="rating"
                                          starDimension="30px"
                                          starSpacing="1.5px"
                                        // ratingValue={console.log(item.review?.[0]?.rating)}
                                        // fillColor="green"
                                        // size={20}
                                        /* Other props */
                                        />
                                      )}
                                    {orderDetailsData?.status ===
                                      "DELIVERED" && <br />}
                                    {item.review?.[0]?.rating ? (
                                      <span
                                        style={{
                                          fontSize: "13px",
                                          color: "blue",
                                          cursor: "pointer",
                                        }}
                                        data-bs-toggle="modal"
                                        data-bs-target="#ratingOrderModal"
                                        onClick={() => {
                                          handleClear(item);
                                        }}
                                      >
                                        {item.review?.[0]?.review_message &&
                                          item.review?.[0]?.review_message !==
                                          "null"
                                          ? "Edit Review"
                                          : "Write a Review"}
                                      </span>
                                    ) : null}
                                  </td>
                                  <td>{item.color.size.name}</td>
                                  <td className="pro-price">


                                    {currencySymbol === "₹" ? "₹" : "$"}
                                    {(item.price * 1).toLocaleString("en-IN", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })} {" "}
                                    {item.price < item.color.MRP && <del style={{ fontSize: "12px", color: "gray" }}>
                                      {currencySymbol === "₹" ? "₹" : "$"}
                                      {(item.color.MRP * 1).toLocaleString("en-IN", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                    </del>}
                                  </td>
                                  <td className="pro-quantity">
                                    {item.quantity}
                                  </td>
                                  {item.order_item_status === "Unavailable" ? (
                                    <td>
                                      {currencySymbol === "₹" ? "₹" : "$"}
                                      {(
                                        item.price * item.quantity
                                      ).toLocaleString("en-IN", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                    </td>
                                  ) : (
                                    <td>
                                      {currencySymbol === "₹" ? "₹" : "$"}
                                      {(
                                        item.color.price * item.quantity
                                      ).toLocaleString("en-IN", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                    </td>
                                  )}
                                </tr>
                              ) : item.order_item_status === "Available" ? (
                                <tr key={item.order_item_id}>
                                  <td className="pro-thumbnail">
                                    {orderDetailsData?.status ===
                                      "ORDER_PLACED" ? (
                                      <div className="form-check d-flex justify-content-center">
                                        {item.order_item_status ===
                                          "Unavailable" ? (
                                          <div
                                            style={{
                                              color: "red",
                                              marginLeft: "-15px",
                                            }}
                                          >
                                            CANCELED
                                          </div>
                                        ) : (
                                          <input
                                            className="form-check-input p-2"
                                            type="checkbox"
                                            value=""
                                            id="flexCheckDefault"
                                            checked={selectedItems.includes(
                                              item
                                            )}
                                            onChange={(event) =>
                                              handleCheckboxClick(event, item)
                                            }
                                          />
                                        )}
                                      </div>
                                    ) : (
                                      <div>{i + 1}</div>
                                    )}
                                  </td>
                                  <td className="pro-thumbnail">
                                    <img
                                      className="img-fluid w-75"
                                      src={item.color.image_url.split("@")[0]}
                                      alt="Product"
                                    />
                                  </td>
                                  <td className="pro-title">
                                    {item.product.product_name} (
                                    {item.color.color.split(",")[0]}){" "}
                                    {orderDetailsData?.status ===
                                      "DELIVERED" && <br />}
                                    {orderDetailsData?.status ===
                                      "DELIVERED" && (
                                        <StarRatings
                                          className="my-1"
                                          // onClick={(rating) => handleRating(item, rating)}
                                          rating={
                                            item.review?.[0]?.rating
                                              ? item.review?.[0]?.rating
                                              : 0
                                          }
                                          starRatedColor="#ffdf00"
                                          changeRating={(rating) =>
                                            handleRating(item, rating)
                                          }
                                          numberOfStars={5}
                                          name="rating"
                                          starDimension="30px"
                                          starSpacing="1.5px"
                                        // ratingValue={console.log(item.review?.[0]?.rating)} 
                                        // fillColor="green"
                                        // size={20}
                                        /* Other props */
                                        />
                                      )}
                                    {orderDetailsData?.status ===
                                      "DELIVERED" && <br />}
                                    {item.review?.[0]?.rating ? (
                                      <span
                                        style={{
                                          fontSize: "13px",
                                          color: "blue",
                                          cursor: "pointer",
                                        }}
                                        data-bs-toggle="modal"
                                        data-bs-target="#ratingOrderModal"
                                        onClick={() => {
                                          handleClear(item);
                                        }}
                                      >
                                        {item.review?.[0]?.review_message &&
                                          item.review?.[0]?.review_message !==
                                          "null"
                                          ? "Edit Review"
                                          : "Write a Review"}
                                      </span>
                                    ) : null}
                                  </td>
                                  <td>{item.color.size.name}</td>
                                  <td className="pro-price">

                                    {currencySymbol === "₹" ? "₹" : "$"}
                                    {(item.price * 1).toLocaleString("en-IN", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}{" "}
                                    {item.price < item.color.MRP && <del style={{ fontSize: "12px", color: "gray" }}>
                                      {currencySymbol === "₹" ? "₹" : "$"}
                                      {(item.color.MRP * 1).toLocaleString("en-IN", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                    </del>}
                                  </td>
                                  <td className="pro-quantity">
                                    {item.quantity}
                                  </td>
                                  {item.order_item_status === "Unavailable" ? (
                                    <td>
                                      {currencySymbol === "₹" ? "₹" : "$"}
                                      {(
                                        item.price * item.quantity
                                      ).toLocaleString("en-IN", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                    </td>
                                  ) : (
                                    <td>
                                      {currencySymbol === "₹" ? "₹" : "$"}
                                      {(
                                        item.color.price * item.quantity
                                      ).toLocaleString("en-IN", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                    </td>
                                  )}
                                </tr>
                              ) : null
                            ) : null
                          )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {paymentDetails && paymentDetails.subtotal > 0 && (
                <div className="row">
                  {orderDetailsData?.status === "ORDER_PLACED" ? (
                    <div className="mt-3 col-sm-3 p-1">
                      {selectedItems.length > 0 ? (
                        !isLoading ? <button
                          className="sqr-btn1 w-100"
                          // style={{ width: "100%" }}
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#cancelOrderModal"
                        >
                          Cancel Product
                        </button> : <><button
                          className="sqr-btn1 w-100"
                          // style={{ background: "gray" }}
                          type="button"
                        // data-bs-toggle="modal"
                        // data-bs-target="#cancelOrderModal"
                        >
                        </button>
                          <div className="loader-container">
                            <div className="loader"></div>
                          </div></>
                      ) : (
                        <button
                          className="sqr-btn1 w-100"
                          style={{ background: "gray" }}
                          type="button"
                        // data-bs-toggle="modal"
                        // data-bs-target="#cancelOrderModal"
                        >
                          Cancel Product
                        </button>
                      )}
                    </div>
                  ) : (
                    <div className="mt-3 col-sm-3 p-1">
                      {" "}
                      <button
                        onClick={downloadPDF} // downloadPDF // handleGeneratePdf
                        className="sqr-btn1 w-100"
                      >
                        Download Invoice
                      </button>
                    </div>
                  )}
                  {orderDetailsData?.status === "ORDER_PLACED" ? (
                    <div className="mt-3 col-sm-3 p-1">
                      <button onClick={downloadPDF} className="sqr-btn1 w-100">
                        Download Invoice
                      </button>
                    </div>
                  ) : (
                    <div className="mt-3 col-sm-3 p-1"></div>
                  )}

                  <div className="mt-3 col-sm-6 p-1">
                    <div
                      className="cart-calculator-wrapper mt-0 px-0 float-right"
                    // style={{ minWidth: "380px" }}
                    >
                      <div
                        className="cart-calculate-items"
                        style={{ background: "white" }}
                      >
                        {/* cart-calculate-items */}
                        {/* <h3>Cart Totals</h3> */}
                        <div
                          className="table-responsive"
                          style={{ background: "#fafafa" }}
                        >
                          {/* table-responsive */}

                          <div
                            className="total-info1"
                            style={{
                              display: "block",
                              float: "inline-end",
                              width: "100%",
                            }}
                          >
                            <table
                              className="total-info-table total-info-table1"
                              style={{ width: "100%" }}
                            >
                              <tr>
                                <th>Total MRP:</th>
                                <td>
                                  {currencySymbol}
                                  {Number(
                                    orderDetails &&
                                    orderDetails
                                      .reduce((accum, item) => {
                                        const mrp =
                                          item.order_item_status ===
                                          "Available" &&
                                          parseFloat(item.color.MRP);
                                        const quantity =
                                          item.order_item_status ===
                                          "Available" && item.quantity;
                                        return accum + mrp * quantity;
                                      }, 0)
                                      .toFixed(2)
                                  ).toLocaleString("en-IN", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </td>
                              </tr>
                              <tr>
                                <th>Discount on MRP:</th>
                                <td>
                                  - {currencySymbol}
                                  {Number(
                                    orderDetails &&
                                    orderDetails
                                      .reduce((accum, item) => {
                                        const mrp =
                                          item.order_item_status ===
                                          "Available" &&
                                          parseFloat(item.color.MRP);
                                        const quantity =
                                          item.order_item_status ===
                                          "Available" && item.quantity;
                                        const price =
                                          item.order_item_status ===
                                          "Available" &&
                                          parseFloat(item.price);
                                        return accum +
                                          mrp * quantity -
                                          price * quantity >
                                          0
                                          ? accum +
                                          mrp * quantity -
                                          price * quantity
                                          : 0;
                                      }, 0)
                                      .toFixed(2)
                                  ).toLocaleString("en-IN", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </td>
                              </tr>
                              <tr>
                                <th>Additional Discount</th>
                                <td>
                                  - {currencySymbol}
                                  {(
                                    paymentDetails?.discount * 1
                                  ).toLocaleString("en-IN", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </td>
                              </tr>
                              <tr>
                                <th className="pb-3">Delivery Charges</th>
                                <td className="pb-3" style={{ color: "green" }}>
                                  Free
                                </td>
                              </tr>

                              <tr
                                style={{
                                  background: "#333c4a",
                                  color: "white",
                                }}
                              >
                                <th>Total Amount:</th>
                                <td>
                                  {currencySymbol}
                                  {Number(
                                    orderDetails &&
                                    orderDetails
                                      .reduce((accum, item) => {
                                        const quantity =
                                          item.order_item_status ===
                                          "Available" && item.quantity;
                                        const price =
                                          item.order_item_status ===
                                          "Available" &&
                                          parseFloat(item.price);
                                        return accum + price * quantity;
                                      }, 0)
                                      .toFixed(2) - paymentDetails?.discount
                                  ).toLocaleString("en-IN", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                      {/* <Link to="/checkout" className="sqr-btn1 d-block">
                            Proceed To Checkout
                          </Link> */}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
