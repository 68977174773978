import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  addUnregisteredProductRating,
  checkPincode,
  getProductSpecifications,
  getReviewByColorId,
} from "../../services/Products.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  addToCart,
  cartAvailability,
  createCart,
  getCartItems,
} from "../../services/Cart.service";
import { useDispatch } from "react-redux";
import {
  addItemToPreLoginCart,
  userCartDetails,
  userCartItems,
} from "../../redux/actions/Actions";
import { useForm } from "react-hook-form";
import { getPincode } from "../../services/Order.service";
import { PATH, RESTAURANT_ID } from "../../redux/constants/Constants";
import Magnifier from "../features/Magnifier";
import StarRatings from "react-star-ratings";

export default function ProductDetails() {
  const currencySymbol = useSelector(
    (state) => state.status.restaurant.currency_symbol
  );
  const cartItemList = useSelector(
    (state) => state?.cartItems?.cartItems?.results
  );

  const preLoginCount = useSelector(
    (state) => state?.preLoginCart?.preLoginCart?.length
  );
  // console.log(preLoginCount);
  const preLoginCart = useSelector(
    (state) => state?.preLoginCart?.preLoginCart
  );
  // console.log(preLoginCart);

  const location = useLocation();
  const navigate = useNavigate();
  const [productsSize, setProductsSize] = useState();
  const [isLoading2, setIsLoading2] = useState(false);
  const [sizes, setSizes] = useState([
    { name: "S" },
    { name: "M" },
    { name: "L" },
    { name: "XL" },
  ]);
  const [selectedSize, setSelectedSize] = useState(null);
  const [isAvailable, setIsAvailable] = useState(true);
  const [propularProducts, setPropularProducts] = useState(null);
  const [productSpecification, setProductSpecification] = useState(null);
  const [productFirstImages, setProductFirstImages] = useState(null);
  const [productImages, setProductImages] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [pincode, setPincode] = useState(null);
  const dispatch = useDispatch();

  const [firstProductData, setFirstProductData] = useState(
    location?.state?.firstProductData
  );
  const [colorObj, setColorObj] = useState(location?.state?.colorObj);
  const [productsByColor, setProductsByColor] = useState(
    location?.state?.productsByColor
  );
  const subCategory = location?.state?.subCategory;
  const token = useSelector((state) => state.status.restaurant.Token);

  const [selectedProduct, setSelectedProduct] = useState(
    location?.state?.productsByColor
  );
  // console.log(productImages);
  const cartId = useSelector(
    (state) => state.cart.cart.results && state.cart.cart.results[0].id
  );
  const userData = useSelector((state) => state.user.user.token);
  const [presentInCart, setPresentInCart] = useState();
  const [presentInPreLoginCart, setPresentInPreLoginCart] = useState();

  const [productID, setProductID] = useState(
    location?.state?.productsByColor?.product
  );

  const [colorName, setColorName] = useState(
    location?.state?.productsByColor?.color
  );

  const [ratingAndReview, setRatingAndReview] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // State for loading indicator
  // const [toaster, setToaster] = useState(true);

  // console.log(cartItemList);
  // console.log(selectedProduct);
  // console.log(ratingAndReview);

  const fetchAPIs = () => {
    productID &&
      colorName &&
      getReviewByColorId(token, colorName.split(",")[0], productID).then(
        (res) => {
          // console.log(res?.data);
          setRatingAndReview(res?.data);
        }
      );
  };

  // console.log(selectedProduct);

  const [availableColors, setAvailableColors] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);

    // productID &&
    //   colorName &&
    //   getReviewByColorId(token, colorName.split(",")[0], productID).then(
    //     (res) => {
    //       console.log(res?.data);
    //       setRatingAndReview(res?.data);
    //     }
    //   );

    // fetchAPIs();

    cartId &&
      getCartItems(token, cartId).then((res) => {
        res && dispatch(userCartItems(res?.data));

        // console.log(res?.data);
      });

    // console.log(productsSize);

    // console.log(location?.state?.productsByColor);
    if (location?.state?.productsByColor) {
      if (Array.isArray(location?.state?.productsByColor)) {
        getReviewByColorId(
          token,
          location?.state?.productsByColor[0]?.color.split(",")[0],
          location?.state?.productsByColor[0]?.product
        ).then((res) => {
          // console.log(res?.data);
          setRatingAndReview(res?.data);
        });
      } else {
        getReviewByColorId(
          token,
          location?.state?.productsByColor?.color.split(",")[0],
          location?.state?.productsByColor?.product
        ).then((res) => {
          // console.log(res?.data);
          setRatingAndReview(res?.data);
        });
      }

      if (
        Array.isArray(location?.state?.productsByColor) &&
        location?.state?.productsByColor.length > 0
      ) {
        setProductsByColor(location?.state?.productsByColor[0]);
        setSelectedProduct([location?.state?.productsByColor[0]]);

        const isSelectedProductInCart = cartItemList?.some(
          (item) =>
            item.color.color_id ===
              location?.state?.productsByColor[0]?.color_id &&
            item.color.size.id === location?.state?.productsByColor[0]?.size.id
        );

        const isSelectedProductInPreLoginCart = preLoginCart?.some(
          (item) =>
            item.color_id === location?.state?.productsByColor[0]?.color_id &&
            item.size_id === location?.state?.productsByColor[0]?.size.id
        );

        setPresentInCart(isSelectedProductInCart);
        setPresentInPreLoginCart(isSelectedProductInPreLoginCart);
        // console.log(isSelectedProductInPreLoginCart);

        // console.log(location?.state?.productsByColor[0].size?.name);
        setSelectedSize(location?.state?.productsByColor[0].size?.name);
        setIsAvailable(location?.state?.productsByColor[0].quantity > 0);
      } else {
        setProductsByColor(location?.state?.productsByColor);
        setSelectedProduct([location?.state?.productsByColor]);

        const isSelectedProductInCart = cartItemList?.some(
          (item) =>
            item?.color?.color_id ===
              location?.state?.productsByColor?.color_id &&
            item?.color?.size.id === location?.state?.productsByColor?.size.id
        );

        const isSelectedProductInPreLoginCart = preLoginCart?.some(
          (item) =>
            item.color_id === location?.state?.productsByColor?.color_id &&
            item.size_id === location?.state?.productsByColor?.size.id
        );

        setPresentInCart(isSelectedProductInCart);
        setPresentInPreLoginCart(isSelectedProductInPreLoginCart);
        // console.log(isSelectedProductInPreLoginCart);
        setSelectedSize(location?.state?.productsByColor.size?.name);
        setIsAvailable(location?.state?.productsByColor.quantity > 0);
      }

      setColorObj(location?.state?.colorObj);

      setFirstProductData(location?.state?.firstProductData);
    }
    /** Filter product Sizes */
    const productSize = colorObj?.filter(
      (item) => item.color === productsByColor?.color
    );
    setProductsSize(productSize);

    let targetColor = productsByColor?.color?.split(",")[0];

    /** Similar products */
    const uniqueColors = {};
    // console.log(colorObj);
    // Iterate through each color object
    colorObj?.forEach((colorObj1) => {
      // console.log(colorObj1);
      const { color, size, quantity } = colorObj1;
      // console.log(color);

      const [colorName, colorCode] = color.split(","); // Split color string into name and code
      // console.log(size);
      // console.log(colorName);
      // Filtering out sizes other than S, M, L, XL
      if (
        size.name === "S" ||
        size.name === "M" ||
        size.name === "L" ||
        size.name === "XL"
      ) {
        if (
          !(colorName in uniqueColors) ||
          (size.name === "S" && quantity > 0) ||
          (size.name === "M" &&
            uniqueColors[colorName].size.name !== "S" &&
            quantity > 0) ||
          (size.name === "L" &&
            uniqueColors[colorName].size.name !== "S" &&
            uniqueColors[colorName].size.name !== "M" &&
            quantity > 0) ||
          (size.name === "XL" &&
            uniqueColors[colorName].size.name !== "S" &&
            uniqueColors[colorName].size.name !== "M" &&
            uniqueColors[colorName].size.name !== "L" &&
            quantity > 0)
        ) {
          uniqueColors[colorName] = colorObj1;
        }
      }
    });

    // Convert the uniqueColors object into an array
    const uniqueColorsArray = Object.values(uniqueColors);

    const availableColorsArray = uniqueColorsArray
      .map((item) => item.color.split(",")[0])
      .join(", ");

    // console.log(availableColorsArray)
    setAvailableColors(availableColorsArray);
    // Output the unique colors and their minimum sizes
    const propularProduct = uniqueColorsArray?.filter(
      (product) => !product.color.split(",").includes(targetColor)
    );
    setPropularProducts(propularProduct);
    /** /. Similar products */

    firstProductData?.product_id &&
      getProductSpecifications(token, firstProductData.product_id).then(
        (res) => {
          if (res?.data != []) {
            setProductSpecification(res?.data);
          } else {
            setProductSpecification(null);
          }
        }
      );

    if (productsByColor?.image_url) {
      setProductFirstImages(productsByColor.image_url.split("@")[0]);
      const images = productsByColor.image_url
        .split("@")
        .map((image) => image.trim())
        .filter(Boolean);
      setProductImages(images);
    } else {
      setProductImages([]);
    }
  }, [productsByColor, colorObj]);

  const handleSizeChange = (size, id, item) => {
    setIsLoading(false);
    // console.log(size);
    // console.log(id);
    // console.log(item);
    // console.log(productsSize);
    setSelectedSize(size);
    setQuantity(1);

    // console.log(cartItemList);
    // console.log(selectedProduct);

    !productsSize.some(
      (sizeItem) => sizeItem.size.name === size && sizeItem.quantity > 0
    )
      ? setIsAvailable(false)
      : setIsAvailable(true);

    const selectedProductData = productsSize.filter(
      (sizeItem) => sizeItem.size.name === size
    );

    setSelectedProduct(selectedProductData);

    // console.log(selectedProductData);

    const isSelectedProductInCart = cartItemList?.some(
      (item) =>
        item?.color?.color_id === selectedProductData[0]?.color_id &&
        item?.color?.size.id === selectedProductData[0]?.size.id
    );

    const isSelectedProductInPreLoginCart = preLoginCart?.some(
      (item) =>
        item.color_id === selectedProductData[0]?.color_id &&
        item.size_id === selectedProductData[0]?.size.id
    );

    setPresentInCart(isSelectedProductInCart);
    setPresentInPreLoginCart(isSelectedProductInPreLoginCart);
    // console.log(isSelectedProductInPreLoginCart);
  };

  // console.log(selectedProduct);
  // console.log(availableColors)

  const handleImages = (item) => {
    // console.log(item);
    setProductFirstImages(item);
  };

  const {
    register,
    reset,
    setError,
    clearErrors,
    handleSubmit: handleSubmitPincode,
    formState: { errors },
  } = useForm();

  const handleProductDetails = (item) => {
    setShowAllReviews(false);
    setIsLoading(false);
    // console.log(item);
    setIsAvailable(item.quantity > 0);
    setProductsByColor(item);
    setSelectedProduct([item]);
    setQuantity(1);
    const productSize = colorObj?.filter(
      (item) => item.color === productsByColor?.color
    );
    setProductsSize(productSize);
    // console.log(item.size.name);
    setSelectedSize(item.size.name);
    navigate(`/product-details`, {
      state: {
        firstProductData: firstProductData,
        colorObj: colorObj,
        productsByColor: item,
        subCategory: subCategory,
      },
    });
  };

  // console.log(firstProductData);

  const handleIncrementQuantity = () => {
    // console.log(selectedProduct[0].quantity);
    setQuantity(quantity + 1);
  };

  const handleDecrementQuantity = () => {
    setQuantity(quantity - 1);
  };

  const handleAddToCart = (item) => {
    navigate(`/product-details`, {
      state: {
        firstProductData: firstProductData,
        colorObj: colorObj,
        productsByColor: item,
        subCategory: subCategory,
      },
    });
    // console.log(item);

    // setPresentInCart(true);
    setIsLoading(true);

    if (userData) {
      cartAvailability(token, userData.id).then((res) => {
        // console.log(res?.data);
        // console.log(res?.data.results?.length < 1);

        if (res?.data.results?.length < 1) {
          const data = {
            customer_id: userData.id,
            restaurant: RESTAURANT_ID,
          };

          createCart(token, data).then((res) => {
            // console.log(res?.data);

            let data;

            if (Array.isArray(item)) {
              data = {
                price: item[0].price,
                quantity: quantity,
                cart_id: res?.data.id,
                product_id: item[0].product,
                color_id: item[0].color_id,
              };
            } else {
              data = {
                price: item.price,
                quantity: quantity,
                cart_id: res?.data.id,
                product_id: item.product,
                color_id: item.color_id,
              };
            }

            // console.log(data);

            addToCart(token, data).then(async (res) => {
              const resData = await res?.data;
              setPresentInCart(true);

              // console.log(resData);

              dispatch(userCartItems(resData));

              toast.success("Product added to the cart!", {
                autoClose: 1500,
                style: {
                  backgroundColor: "black",
                  color: "white",
                },
                containerId: "productDetails",
              });

              // setIsLoading(false);
              // setTimeout(() => {
              //   setToaster(false)
              // }, 1500);
            });

            cartAvailability(token, userData.id).then((res) => {
              // console.log(res?.data);
              dispatch(userCartDetails(res?.data));
            });
          });
        } else {
          let data;

          if (Array.isArray(item)) {
            data = {
              price: item[0].price,
              quantity: quantity,
              cart_id: res?.data.results[0]?.id,
              product_id: item[0].product,
              color_id: item[0].color_id,
            };
          } else {
            data = {
              price: item.price,
              quantity: quantity,
              cart_id: res?.data.results[0]?.id,
              product_id: item.product,
              color_id: item.color_id,
            };
          }

          // console.log(data);

          addToCart(token, data).then(async (res) => {
            const resData = await res?.data;
            setPresentInCart(true);

            // console.log(resData);

            dispatch(userCartItems(resData));

            toast.success("Product added to cart!", {
              autoClose: 1500,
              style: {
                backgroundColor: "black",
                color: "white",
              },
              containerId: "productDetails",
            });

            // setIsLoading(false);
            // setTimeout(() => {
            //   setToaster(false)
            // }, 1500);
          });

          // console.log(data);

          dispatch(userCartDetails(res?.data));
          // console.log(res?.data);
        }
      });
    } else {
      // console.log(item);
      let data;

      if (Array.isArray(item)) {
        data = {
          price: item[0].price,
          quantity: quantity,
          product_id: item[0].product,
          color_id: item[0].color_id,
          size: item[0].size.name,
          color: item[0].color,
          image_url: item[0].image_url,
          product_name: firstProductData?.product_name,
          size_id: item[0].size.id,
        };
      } else {
        data = {
          price: item.price,
          quantity: quantity,
          product_id: item.product,
          color_id: item.color_id,
          size: item.size.name,
          color: item.color,
          image_url: item.image_url,
          product_name: firstProductData?.product_name,
          size_id: item.size.id,
        };
      }

      data && dispatch(addItemToPreLoginCart(data));
      setPresentInPreLoginCart(true);

      // console.log(data);

      toast.success("Product added to cart!", {
        autoClose: 1500,
        style: {
          backgroundColor: "black",
          color: "white",
        },
        containerId: "productDetails",
      });

      // setTimeout(() => {
      //   navigate("/login");
      // }, 3000);
    }

    // setIsLoading(false);
    // setTimeout(() => {
    //   setToaster(false)
    // }, 1500);

    // console.log(item);
  };

  const [zipCode, setZipCode] = useState(null);

  const handleCheckPincode = (item) => {
    // console.log(item);
    // console.log(zipCode);
    // checkPincode(token, item.pincode).then((res) => {
    //   console.log(res?.data);
    //   setPincode(res?.data.status);
    // });
    const pincode = item.pincode;
    pincode &&
      getPincode(token, zipCode).then((res) => {
        // console.log(res?.data?.delivery_codes[0]);
        if (!res?.data?.delivery_codes[0]) {
          setError("pincode", {
            type: "manual",
            message: "Provided pincode is not serviceable",
          });
          setPincode(false);
        } else {
          setError("pincode", {
            type: "manual",
            message: "Delivery is available",
          });

          // clearErrors("pincode")
          setPincode(true);
        }
      });
  };

  const handlePincode = (e) => {
    clearErrors("pincode");
    setZipCode(e.target.value);
    // console.log(e.target.value);
    const pincode = e.target.value;
    if (pincode.length > 5) {
      getPincode(token, pincode).then((res) => {
        // console.log(res?.data?.delivery_codes[0]);
        if (!res?.data?.delivery_codes[0] || !e.target.value) {
          setError("pincode", {
            type: "manual",
            message: "Provided pincode is not serviceable",
          });
          setPincode(false);
        } else {
          setError("pincode", {
            type: "manual",
            message: "Delivery is available",
          });
          // clearErrors("pincode")
          setPincode(true);
        }
      });
    } else {
      clearErrors("pincode");
      setPincode(null);
    }
  };

  const handleLogin = () => {
    toast.warning("Please login to view your cart!", {
      autoClose: 1500,
      style: {
        backgroundColor: "black",
        color: "white",
      },
      containerId: "productDetails",
    });

    setTimeout(() => {
      navigate(`/login`);
    }, 3000);
  };

  const [uploadImages, setUploadImages] = useState([]);
  const [reviewMessage, setReviewMessage] = useState("");

  const {
    register: register2,
    setValue: setValue2,
    reset: reset2,
    clearErrors: clearErrors2,
    handleSubmit: handleSubmitRating,
    formState: { errors: errors2 },
  } = useForm();

  const reviewProduct = selectedProduct?.[0];
  // console.log(reviewProduct);

  const reviewOrder = (item) => {
    setIsLoading2(true);

    // console.log(item);
    // console.log(uploadImages);

    const data = {
      product_id: reviewProduct?.product,
      color: reviewProduct?.color_id,
      rating: item.rating,
      review_message: item.review,
      is_registered_user: false,
      restaurant: RESTAURANT_ID,
      image: uploadImages.length > 0 ? uploadImages : null,
      unregistered_user_name: item.name,
    };

    // console.log(data);

    addUnregisteredProductRating(data).then((res) => {
      // console.log(res?.data);
      if (res?.data) {
        setUploadImages([]);

        const displayedImagesElement =
          document.getElementById("displayedImagesAdd");
        if (displayedImagesElement !== null) {
          displayedImagesElement.innerHTML = "";
        }

        res?.data &&
          toast.success(
            "Thank you for your review! It is currently being reviewed for approval.",
            {
              autoClose: 2500,
              style: {
                backgroundColor: "black",
                color: "white",
              },
              containerId: "productDetails",
            }
          );
        setIsLoading2(false);
        document.getElementById("ratingCancelModal").click();
        fetchAPIs();
        setUploadImages([]);
      }
    });
  };

  const handleClear = () => {
    reset2();
    setReviewMessage(null);
    setValue2("review", "");
    setValue2("rating", null);
    clearErrors2();
    setUploadImages([]);

    const displayedImagesElement =
      document.getElementById("displayedImagesAdd");
    if (displayedImagesElement !== null) {
      displayedImagesElement.innerHTML = "";
    }

    setRating(0);
  };

  const addImage = (event) => {
    const input = event.target;
    if (input.files && input.files.length > 0) {
      const newImages = Array.from(input.files);

      // Update the state to include new images
      setUploadImages((prevImages) => [...prevImages, ...newImages]);

      // Optionally, you can display the uploaded images here
      newImages.forEach((file) => {
        const reader = new FileReader();
        reader.onload = function (e) {
          // Display the uploaded image
          const imgContainer = document.createElement("div");
          imgContainer.classList.add("img-container1");

          const img = document.createElement("img");
          img.src = e.target.result;
          img.classList.add("uploaded-image");

          // Add a remove button
          const removeButton = document.createElement("button");
          removeButton.innerText = "X";
          removeButton.classList.add("remove-button");
          removeButton.addEventListener("click", () => {
            imgContainer.remove();
            setUploadImages((prevImages) =>
              prevImages.filter((prevFile) => prevFile !== file)
            );
          });

          imgContainer.appendChild(img);
          imgContainer.appendChild(removeButton);
          document
            .getElementById("displayedImagesAdd")
            .appendChild(imgContainer);
        };
        reader.readAsDataURL(file);
      });

      // setError2("uploadImages", { type: "manual", message: "" });
    }
  };

  const handleAddImageClick = () => {
    // Trigger the click event on the file input when the displayed image is clicked
    document.getElementById("imageInputAdd").click();
  };

  const handleChange = (event) => {
    setReviewMessage(event.target.value);
    setValue2("review", event.target.value);
    clearErrors2();
  };

  const [ratings, setRatings] = useState({});

  const [imageUrl, setImageUrl] = useState("");
  const [time, setTime] = useState("");

  // console.log(subCategory);

  const [showAllReviews, setShowAllReviews] = useState(false);

  const [rating, setRating] = useState(0); // Initialize state for the rating

  const changeRating = (newRating) => {
    setRating(newRating); // Update the rating state
    clearErrors2("rating");
    setValue2("rating", newRating);
  };

  const sortedByLatest = ratingAndReview?.reviews?.sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );

  // Prioritize reviews with images
  const sortedReviews = sortedByLatest?.sort((a, b) => {
    if (a.image_url && !b.image_url) return -1;
    if (!a.image_url && b.image_url) return 1;
    return 0;
  });

  // console.log(showAllReviews);

  // console.log(sortedByLatest);

  const displayedReviews = showAllReviews
    ? sortedReviews
    : sortedReviews?.slice(0, 2);

  // console.log(sortedReviews);

  return (
    <div style={{ minHeight: "800px" }} className="">
      {/* padding-x */}
      {/* <!-- page main wrapper start --> */}
      <div className="product-details-wrapper section-padding mx-3 ptp-35">
        <ToastContainer
          position="top-center"
          className="mt-4 ToastContainer"
          limit={1}
          containerId="productDetails"
        />
        <Magnifier
          containerSelector=".image-container"
          magnification={4}
          magnifierSize="150px"
        />

        {/* Size Guide Modal */}
        <div
          className="modal fade mt-5"
          id="sizeGuideModal"
          tabIndex="-1"
          aria-labelledby="sizeGuideModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog mt-5">
            <div className="modal-body">
              <div className="size-chart-modal">
                <div className="d-flex justify-content-between">
                  <h5 className="mb-3">
                    {firstProductData?.master_category} Clothing Size Chart -
                    (inch)
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    id="sizeGuideCloseModal"
                  ></button>
                </div>

                <table
                  className="sizeChart"
                  border="0"
                  cellPadding="10"
                  cellSpacing="0"
                  width="100%"
                >
                  {/(Women|women|Women's|women's|Womens|womens)/i.test(
                    firstProductData?.master_category
                  ) ? (
                    <thead>
                      <tr>
                        <td>Size</td>
                        <td>Chest</td>
                        <td>Shoulder</td>
                        <td>Length</td>
                        <td>Waist</td>
                      </tr>
                    </thead>
                  ) : (
                    <thead>
                      <tr>
                        <td>Size</td>
                        <td>Chest</td>
                        <td>Shoulder</td>
                        <td>Length</td>
                      </tr>
                    </thead>
                  )}
                  {/(Women|women|Women's|women's|Womens|womens)/i.test(
                    firstProductData?.master_category
                  ) ? (
                    <tbody>
                      <tr>
                        <td>S</td>
                        <td>36"</td>
                        <td>15"</td>
                        <td>26"</td>
                        <td>30"</td>
                      </tr>
                      <tr style={{ background: "gainsboro" }}>
                        <td>M</td>
                        <td>38"</td>
                        <td>16"</td>
                        <td>27"</td>
                        <td>32"</td>
                      </tr>
                      <tr>
                        <td>L</td>
                        <td>40"</td>
                        <td>17"</td>
                        <td>28"</td>
                        <td>34"</td>
                      </tr>

                      <tr style={{ background: "gainsboro" }}>
                        <td>XL</td>
                        <td>42"</td>
                        <td>18"</td>
                        <td>29"</td>
                        <td>36"</td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td>S</td>
                        <td>36"</td>
                        <td>16"</td>
                        <td>26"</td>
                      </tr>
                      <tr style={{ background: "gainsboro" }}>
                        <td>M</td>
                        <td>38"</td>
                        <td>17"</td>
                        <td>27"</td>
                      </tr>
                      <tr>
                        <td>L</td>
                        <td>40"</td>
                        <td>18"</td>
                        <td>28"</td>
                      </tr>

                      <tr style={{ background: "gainsboro" }}>
                        <td>XL</td>
                        <td>42"</td>
                        <td>19"</td>
                        <td>29"</td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* end */}

        {/* Image Preview Modal */}
        <div
          className="modal fade"
          id="imagePreviewModal"
          tabIndex="-1"
          aria-labelledby="imagePreviewModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div
              className="modal-body p-1 ms-0 mt-5"
              style={{ background: "#ffffff", border: "1px solid #000" }}
            >
              <div className="d-flex justify-content-between">
                <h5 className="mb-1">{time}</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="imagePreviewCloseModal"
                ></button>
              </div>
              <img
                src={imageUrl}
                alt="imgUrl"
                className="h-100 w-100"
                // style={{ maxHeight: "600px" }}
              />
            </div>
          </div>
        </div>
        {/* end */}

        {/* Rating Order Modal */}
        <div
          className="modal fade ms-2"
          id="ratingOrderModal"
          tabindex="-1"
          aria-labelledby="ratingOrderModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-body">
              <div>
                <div className="login-reg-form-wrap">
                  <div className="d-flex justify-content-between mb-0">
                    <h2 className="mb-2 pb-2">Review Product</h2>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="ratingCancelModal"
                    ></button>
                  </div>

                  <form
                    action="#"
                    method="post"
                    onSubmit={handleSubmitRating(reviewOrder)}
                  >
                    {/* <div className=""></div> */}
                    <label>Your Name</label>{" "}
                    <i className="text-danger">
                      <b>*</b>
                    </i>
                    <div className="single-input-item mb-3 mt-1">
                      <input
                        className=""
                        style={{ borderRadius: "0px" }}
                        placeholder="Enter Name"
                        {...register2("name", {
                          required: "Please Enter Your Name",
                        })}
                      />
                      {errors2.name && (
                        <p className="error mtn-11">{errors2.name.message}</p>
                      )}
                    </div>
                    <label>Rate the Product</label>{" "}
                    <i className="text-danger">
                      <b>*</b>
                    </i>
                    <div className="single-input-item mb-3 mt-1">
                      <StarRatings
                        // className="my-2"
                        rating={rating} // Bind the rating state
                        {...register2("rating", {
                          required: "Please Give Rating",
                        })}
                        starRatedColor="#ffdf00"
                        numberOfStars={5}
                        name="rating"
                        starDimension="30px"
                        starSpacing="1.5px"
                        changeRating={changeRating} // Allow changing the rating
                      />
                      {errors2.rating && (
                        <p className="error mtn-11">{errors2.rating.message}</p>
                      )}
                    </div>
                    {/* <label>Write a Review</label>{" "} */}
                    {/* <i className="text-danger">
                      <b>*</b>
                    </i> */}
                    <div className="single-input-item mb-2 mt-4">
                      <textarea
                        type="text"
                        placeholder="Write a Review"
                        {...register2("review")}
                        value={reviewMessage}
                        onChange={(event) => handleChange(event)}
                        maxLength={1000}
                      />
                    </div>
                    {/* {review?.review?.[0]?.image_url ? (
                            <label>Photo</label>
                          ) : ( */}
                    <label>Add Photo</label>
                    {/* )} */}
                    <div className="product-modal2 row mb-2">
                      {/* {review?.review?.[0]?.image_url ? null : ( */}
                      <div className="col-2">
                        <input
                          type="file"
                          id="imageInputAdd"
                          accept="image/*"
                          multiple // Allow multiple file selection
                          {...register2("uploadImages")}
                          style={{ display: "none" }}
                          onChange={addImage}
                        />
                        {/* Displayed image */}
                        <img
                          src={`${PATH}/assets/img/bamboosa/upload.png`}
                          alt="Upload"
                          className="mt-0"
                          onClick={() => handleAddImageClick()}
                        />
                      </div>
                      {/* )} */}

                      {/* {review?.review?.[0]?.image_url ? (
                              <div id="displayedImagesAdd2" className="col-md-10">
                                {review?.review?.[0]?.image_url
                                  .split("@")
                                  .map((url, index) => (
                                    <img key={index} src={url} alt={`img-${index}`} />
                                  ))}
                              </div>
                            ) : ( */}
                      <div id="displayedImagesAdd" className="col-md-10"></div>
                      {/* )} */}
                    </div>
                    <div className="single-input-item">
                      {!isLoading2 ? (
                        <button className="sqr-btn w-100" type="submit">
                          Submit
                        </button>
                      ) : (
                        <button className="sqr-btn w-100" type="button">
                          Submitting...
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end */}

        <div className="container custom-container">
          <div className="row">
            {/* <div className="col-lg-1"></div> */}
            <div className="col-lg-9">
              {/* <!-- product details inner end --> */}
              <div className="product-details-inner">
                <div className="row">
                  <div className="col-md-5">
                    <div className="product-large-slider mb-20 fix-product px-2">
                      <div className="pro-large-img img-zoom image-container">
                        <img
                          src={productFirstImages}
                          alt="Product-Img"
                          className="w-100 magnifiedImg"
                          // style={{ height: 420 }}
                        />
                      </div>
                      <div className="pro-nav slick-row-10 slick-initialized slick-slider">
                        <div
                          className="slick-list mt-2 draggable scroll-x"
                          style={{ padding: 0 }}
                        >
                          <div
                            className="slick-track scroll-x d-flex"
                            // style={{ opacity: 1, width: 448 }}
                          >
                            {productImages?.map((item, i) => (
                              <div
                                className="pro-nav-thumb slick-slide slick-current slick-center"
                                data-slick-index="0"
                                aria-hidden="true"
                                tabIndex="0"
                                style={{ width: 95, height: 120 }}
                                key={i}
                                onClick={() => handleImages(item)}
                              >
                                <img
                                  src={item}
                                  alt="Product-Img"
                                  className="w-100 h-100 zoom"
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* similar products */}
                    <div className="sidebar-wrapper px-2 similar1">
                      {/* rating & review */}
                      {ratingAndReview && (
                        <div
                          className="p-2 mt-2 mb-4"
                          style={{ background: "#fafafa" }}
                        >
                          <label className="fs-6 fw-bold ms-1 mt-2 mb-3">
                            Customer Ratings & Reviews
                          </label>
                          <div className="container">
                            <div className="rating-box">
                              <div className="rating-value d-flex">
                                {parseFloat(
                                  ratingAndReview?.average_customer_rating
                                ).toFixed(1)}
                                <span className="star mtn-2">★</span>
                              </div>
                              <div>
                                <div className="ratings-count">
                                  {ratingAndReview.customer_rating_count}{" "}
                                  Ratings
                                </div>
                                <div className="reviews-count">
                                  {ratingAndReview.customer_review_count}{" "}
                                  Reviews
                                </div>
                              </div>
                            </div>

                            <div>
                              {displayedReviews?.map((item, i) =>
                                item.image_url ? (
                                  <div className="review-card mt-3" key={i}>
                                    <div className="">
                                      <div className="reviewer-name mb-1">
                                        {item.first_name + " " + item.last_name}
                                      </div>
                                      <div
                                        className="d-flex"
                                        style={{ height: "30px" }}
                                      >
                                        <div className="review-rating">
                                          {item.rating}
                                          <span className="star ms-1">★</span>
                                        </div>
                                        <div
                                          className="ms-2 fs-1"
                                          style={{
                                            marginTop: "-10px",
                                            color: "gray",
                                          }}
                                        >
                                          .
                                        </div>
                                        <div className="review-date mt-1 ms-1">
                                          Posted on{" "}
                                          {new Date(item.created_at)
                                            .toLocaleDateString("en-US", {
                                              year: "numeric",
                                              month: "long",
                                              day: "numeric",
                                            })}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="review-text">
                                      {item.review_message &&
                                        item.review_message !== "null" &&
                                        item.review_message}
                                    </div>

                                    <div className="product-modal2">
                                      <div id="displayedImagesAdd2">
                                        {item.image_url
                                          .split("@")
                                          .map((url, index) => (
                                            <img
                                              key={index}
                                              src={url}
                                              alt={`img-${index}`}
                                              style={{ zIndex: 10 }}
                                              data-bs-toggle="modal"
                                              data-bs-target="#imagePreviewModal"
                                              onClick={() => {
                                                setImageUrl(url);
                                                setTime(
                                                  item.first_name +
                                                    " " +
                                                    item.last_name
                                                );
                                              }}
                                            />
                                          ))}
                                      </div>
                                    </div>
                                  </div>
                                ) : item.review_message &&
                                  item.review_message !== "null" ? (
                                  <div className="review-card mt-3" key={i}>
                                    <div className="">
                                      <div className="reviewer-name">
                                        {item.first_name + " " + item.last_name}
                                      </div>
                                      <div
                                        className="d-flex"
                                        style={{ height: "30px" }}
                                      >
                                        <div className="review-rating">
                                          {item.rating}
                                          <span className="star ms-1">★</span>
                                        </div>
                                        <div
                                          className="ms-2 fs-1"
                                          style={{
                                            marginTop: "-10px",
                                            color: "gray",
                                          }}
                                        >
                                          .
                                        </div>
                                        <div className="review-date mt-1 ms-1">
                                          Posted on{" "}
                                          {new Date(item.created_at)
                                            .toLocaleDateString("en-US", {
                                              year: "numeric",
                                              month: "long",
                                              day: "numeric",
                                            })}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="review-text">
                                      {item.review_message &&
                                        item.review_message !== "null" &&
                                        item.review_message}
                                    </div>
                                  </div>
                                ) : null
                              )}
                              {!showAllReviews &&
                                ratingAndReview.customer_review_count > 2 && (
                                  <div>
                                    <button
                                      className="btn btn-link fw-bold mt-2"
                                      style={{ textDecoration: "none" }}
                                      onClick={() => setShowAllReviews(true)}
                                    >
                                      Show all reviews
                                    </button>
                                  </div>
                                )}
                              {showAllReviews &&
                                ratingAndReview.customer_review_count > 2 && (
                                  <div>
                                    <button
                                      className="btn btn-link fw-bold mt-2"
                                      style={{ textDecoration: "none" }}
                                      onClick={() => setShowAllReviews(false)}
                                    >
                                      Show less
                                    </button>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      )}

                      {/* end */}

                      {/* <!-- single sidebar end --> */}
                    </div>
                    {/* end */}
                  </div>
                  {firstProductData ? (
                    <div className="col-md-7 product-details-overflow">
                      <div className="product-details-des px-2">
                        <h3>
                          {firstProductData?.product_name} (
                          {selectedProduct[0]?.color?.split(",")[0]})
                        </h3>

                        <div className="price-box">
                          <span className="regular-price-2">
                            {currencySymbol === "₹" ? "₹" : "$"}
                            {selectedProduct[0]?.price
                              ? parseFloat(
                                  selectedProduct[0]?.price * 1
                                ).toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : "0.00"}
                          </span>
                          {selectedProduct[0]?.price >=
                          selectedProduct[0]?.MRP ? null : (
                            <span className="old-price-2 ms-2">
                              <del>
                                {currencySymbol === "₹" ? "₹" : "$"}
                                {(selectedProduct[0]?.MRP * 1).toLocaleString(
                                  "en-IN",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                              </del>
                            </span>
                          )}
                          {selectedProduct[0]?.price >=
                          selectedProduct[0]?.MRP ? null : (
                            <span className="regular-price-2 ms-2 percent-off">
                              {selectedProduct[0]?.price
                                ? `${(
                                    ((selectedProduct[0]?.MRP -
                                      selectedProduct[0]?.price) /
                                      selectedProduct[0]?.MRP) *
                                    100
                                  ).toFixed(2)}% off`
                                : "0.00"}
                            </span>
                          )}
                        </div>
                        <div>
                          Tax included. <b>Shipping</b> calculated at checkout.
                        </div>

                        {ratingAndReview && (
                          <div className="my-2 d-flex">
                            <StarRatings
                              // onClick={(rating) => handleRating(item, rating)}
                              rating={
                                parseFloat(
                                  ratingAndReview?.average_customer_rating
                                ) || 0
                              }
                              starRatedColor="green"
                              // changeRating={(rating) =>
                              //   handleRating(item, rating)
                              // }
                              numberOfStars={5}
                              name="rating"
                              starDimension="20px"
                              starSpacing="1px"
                            />
                            <label
                              className="ms-2 fs-1"
                              style={{ marginTop: "-8px" }}
                            >
                              .
                            </label>
                            <label className="ms-1 mt-1 fs-6 fw-bold">
                              {parseFloat(
                                ratingAndReview?.average_customer_rating
                              ).toFixed(1)}
                            </label>
                            <label className="ms-2 mt-1">
                              ({ratingAndReview?.customer_rating_count} ratings)
                            </label>
                          </div>
                        )}

                        <div>
                          <button
                            className="btn btn-link fw-bold mt-2 p-0"
                            style={{ textDecoration: "none" }}
                            data-bs-toggle="modal"
                            data-bs-target="#ratingOrderModal"
                            onClick={() => {
                              handleClear();
                            }}
                          >
                            Write a Review
                          </button>
                        </div>

                        <hr className="mb-3 hr" />
                        <h5>Description</h5>
                        <p style={{ textAlign: "justify" }} className="my-3">
                          {/* {firstProductData?.extra} */}
                          Experience the ultimate blend of comfort, style, and
                          sustainability with the BambooSA Classic Bamboo
                          T-Shirt. Crafted from premium bamboo fabric, this
                          T-shirt is designed to elevate your everyday wardrobe
                          while being kind to the planet.
                          <br />
                          By choosing BambooSA, you're not just getting a
                          high-quality T-shirt; you're also supporting
                          sustainable fashion. Our commitment to eco-friendly
                          practices ensures that every BambooSA product you wear
                          contributes to a greener future.
                          <br />
                          Upgrade your wardrobe with the BambooSA Classic Bamboo
                          T-Shirt today and enjoy the perfect fusion of comfort,
                          style, and sustainability. Order now and feel the
                          difference!
                        </p>

                        {productsSize ? (
                          <div className="size-responsive row">
                            <div className="pro-size mb-20 col-sm-9 col-md-9 sizewidth1">
                              <h5>Size :</h5>
                              {/* <select className="nice-select">
                                <option>S</option>
                                <option>M</option>
                                <option>L</option>
                                <option>XL</option>
                            </select> */}
                              {sizes?.map((item, i) => {
                                const isSizePresent = productsSize.find(
                                  (sizeItem) => sizeItem.size.name === item.name
                                );
                                return (
                                  <div key={i}>
                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name="options-outlined"
                                      id={`size-${i}`}
                                      value={item.name}
                                      autoComplete="off"
                                      checked={
                                        selectedSize === item.name ||
                                        (i === 0 && !selectedSize)
                                      }
                                      onChange={() =>
                                        handleSizeChange(
                                          item.name,
                                          isSizePresent?.size?.id,
                                          item
                                        )
                                      }
                                    />
                                    <label
                                      htmlFor={`size-${i}`}
                                      className={`btn btn-size ms-2 ${
                                        !productsSize.some(
                                          (sizeItem) =>
                                            sizeItem.size.name === item.name &&
                                            sizeItem.quantity > 0
                                        )
                                          ? "btn-stroke"
                                          : ""
                                      }`}
                                    >
                                      {item.name}
                                    </label>
                                  </div>
                                );
                              })}
                            </div>
                            <div
                              className="fw-bold mb-2 mt-2 col-sm-3 col-md-3 sizewidth2"
                              data-bs-toggle="modal"
                              data-bs-target="#sizeGuideModal"
                              style={{
                                cursor: "pointer",
                                textDecorationLine: "underline",
                              }}
                            >
                              <img
                                src={`${PATH}/assets/img/bamboosa/size-guide.png`}
                                alt="size"
                                data-bs-toggle="modal"
                                data-bs-target="#sizeGuideModal"
                                style={{ width: "25px" }}
                                className="me-2 size-img zoom"
                              />
                              SIZE GUIDE
                            </div>
                          </div>
                        ) : null}

                        {firstProductData?.in_stock ? (
                          <div className="availability mb-20">
                            <h5>Availability:</h5>
                            <span
                              className={
                                firstProductData?.in_stock === true &&
                                isAvailable === true
                                  ? "success fw-bold"
                                  : "error1 fw-bold"
                              }
                            >
                              {firstProductData?.in_stock === true &&
                              isAvailable === true
                                ? "In Stock"
                                : "Out of Stock"}
                            </span>
                            {selectedProduct[0]?.quantity < 10 &&
                            selectedProduct[0]?.quantity > 0 ? (
                              <strong className="ms-3" style={{ color: "red" }}>
                                Only{" "}
                                {selectedProduct[0]?.quantity < 6
                                  ? selectedProduct[0]?.quantity
                                  : "few"}{" "}
                                left
                              </strong>
                            ) : null}
                          </div>
                        ) : null}
                        <div className="quantity-cart-box d-flex align-items-center mb-20">
                          {cartId ? (
                            firstProductData?.in_stock === true &&
                            isAvailable === true ? (
                              !presentInCart ? (
                                <div className="quantity">
                                  <div className="pro-qty">
                                    {quantity > 1 ? (
                                      <span
                                        className="dec qtybtn"
                                        onClick={() =>
                                          handleDecrementQuantity()
                                        }
                                      >
                                        -
                                      </span>
                                    ) : (
                                      <span className="dec qtybtn">-</span>
                                    )}

                                    <input type="text" value={quantity} />

                                    {selectedProduct[0]?.quantity > quantity ? (
                                      <span
                                        className="inc qtybtn"
                                        onClick={() =>
                                          handleIncrementQuantity()
                                        }
                                      >
                                        +
                                      </span>
                                    ) : (
                                      <span className="inc qtybtn">+</span>
                                    )}
                                  </div>
                                </div>
                              ) : null
                            ) : null
                          ) : firstProductData?.in_stock === true &&
                            isAvailable === true ? (
                            !presentInPreLoginCart ? (
                              <div className="quantity">
                                <div className="pro-qty">
                                  {quantity > 1 ? (
                                    <span
                                      className="dec qtybtn"
                                      onClick={() => handleDecrementQuantity()}
                                    >
                                      -
                                    </span>
                                  ) : (
                                    <span className="dec qtybtn">-</span>
                                  )}

                                  <input type="text" value={quantity} />

                                  {selectedProduct[0]?.quantity > quantity ? (
                                    <span
                                      className="inc qtybtn"
                                      onClick={() => handleIncrementQuantity()}
                                    >
                                      +
                                    </span>
                                  ) : (
                                    <span className="inc qtybtn">+</span>
                                  )}
                                </div>
                              </div>
                            ) : null
                          ) : null}
                          {cartId ? (
                            firstProductData?.in_stock === true &&
                            isAvailable === true ? (
                              presentInCart ? (
                                <div className="product-btn">
                                  <Link
                                    style={{ background: "orange" }}
                                    to={`/cart`}
                                  >
                                    Go to cart
                                  </Link>
                                </div>
                              ) : !isLoading ? (
                                <div
                                  className="product-btn"
                                  onClick={() =>
                                    handleAddToCart(selectedProduct)
                                  }
                                >
                                  <Link to="javascript:void(0)">
                                    Add to cart
                                  </Link>
                                </div>
                              ) : (
                                <div className="product-btn">
                                  <Link to="javascript:void(0)">
                                    Add to cart
                                  </Link>
                                </div>
                              )
                            ) : null
                          ) : firstProductData?.in_stock === true &&
                            isAvailable === true ? (
                            presentInPreLoginCart ? (
                              <div className="product-btn">
                                <Link
                                  style={{ background: "orange" }}
                                  to="javascript:void(0)"
                                  onClick={handleLogin}
                                >
                                  Go to cart
                                </Link>
                              </div>
                            ) : !isLoading ? (
                              <div
                                className="product-btn"
                                onClick={() => handleAddToCart(selectedProduct)}
                              >
                                <Link to="javascript:void(0)">Add to cart</Link>
                              </div>
                            ) : (
                              <div className="product-btn">
                                <Link to="javascript:void(0)">Add to cart</Link>
                              </div>
                            )
                          ) : null}
                        </div>
                        {/* <hr /> */}
                        {/* <div className="availability mb-20">
                          <h5>Delivery & Services</h5><br/>
                          <input type="text" id="pincode" name="pincode" />
                        </div> */}
                        <div className="availability mb-20">
                          <h5>
                            <b>Delivery & Services</b>
                          </h5>
                        </div>
                        <form
                          onClick={handleSubmitPincode(handleCheckPincode)}
                          className="mb-2 row"
                        >
                          <div className="availability mb-20 delivery-input mb-4">
                            <input
                              type="text"
                              id="pincode"
                              name="pincode"
                              placeholder="Check Pincode"
                              {...register("pincode")}
                              maxLength={6}
                              onChange={(e) => handlePincode(e)}
                            />
                            <button type="submit">
                              {
                                pincode ? (
                                  <i
                                    className="fa fa-check-circle success"
                                    aria-hidden="true"
                                  ></i>
                                ) : null
                                // <i
                                //   className="fa fa-times-circle color-red"
                                //   aria-hidden="true"
                                // ></i>
                              }
                              {pincode === false ? (
                                <i
                                  className="fa fa-times-circle color-red"
                                  aria-hidden="true"
                                ></i>
                              ) : null}
                              <span>Check</span>
                            </button>
                          </div>
                          <div className="">
                            {pincode
                              ? errors.pincode && (
                                  <p
                                    className="error mtn-3 fw-bold"
                                    style={{ color: "green" }}
                                  >
                                    {errors.pincode.message}
                                  </p>
                                )
                              : errors.pincode && (
                                  <p className="error mtn-3 fw-bold">
                                    {errors.pincode.message}
                                  </p>
                                )}
                          </div>
                          <div
                            className="fw-bold mb-1"
                            style={{ fontSize: "13.5px" }}
                          >
                            <img
                              src={`${PATH}/assets/img/bamboosa/cancel.png`}
                              alt="cancel"
                              style={{ width: "4.5%" }}
                              className="me-2 zoom"
                            />
                            Cancel anytime before Shipping
                          </div>
                          <div
                            className="fw-bold"
                            style={{ fontSize: "13.5px" }}
                          >
                            <img
                              src={`${PATH}/assets/img/bamboosa/no-cash.png`}
                              alt="no-cash"
                              style={{ width: "5%", marginRight: "1%" }}
                              className="zoom"
                            />
                            Pay on delivery is not available
                          </div>
                        </form>
                        <hr className="hr" />
                        <div className="">
                          <h5>About Product</h5>

                          <div
                            className="accordion mt-3 mb-3"
                            id="accordionExample"
                          >
                            <div className="accordion-item">
                              <h2 className="accordion-header" id="headingOne">
                                <button
                                  className="accordion-button"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseOne"
                                  aria-expanded="true"
                                  aria-controls="collapseOne"
                                >
                                  Details
                                </button>
                              </h2>
                              <div
                                id="collapseOne"
                                className="accordion-collapse collapse show"
                                aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample"
                              >
                                <div className="accordion-body">
                                  <table
                                    style={{
                                      width: "100%",
                                      borderCollapse: "collapse",
                                    }}
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          style={{
                                            textAlign: "left",
                                            padding: "3px",
                                            width: "35%",
                                          }}
                                        >
                                          <strong>Material</strong>
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "left",
                                            padding: "3px",
                                            width: "5%",
                                          }}
                                        >
                                          <strong>:</strong>
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "left",
                                            padding: "3px",
                                            width: "60%",
                                          }}
                                        >
                                          {firstProductData?.sub_category &&
                                          firstProductData?.sub_category ===
                                            "Polo Neck"
                                            ? "95% Bamboo, 5% Spandex"
                                            : "100% Bamboo natural fibre fabric"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          style={{
                                            textAlign: "left",
                                            padding: "3px",
                                          }}
                                        >
                                          <strong>Fit</strong>
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "left",
                                            padding: "3px",
                                          }}
                                        >
                                          <strong>:</strong>
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "left",
                                            padding: "3px",
                                          }}
                                        >
                                          Regular Fit
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          style={{
                                            textAlign: "left",
                                            padding: "3px",
                                          }}
                                        >
                                          <strong>Neckline</strong>
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "left",
                                            padding: "3px",
                                          }}
                                        >
                                          <strong>:</strong>
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "left",
                                            padding: "3px",
                                          }}
                                        >
                                          {firstProductData?.sub_category &&
                                            firstProductData?.sub_category}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          style={{
                                            textAlign: "left",
                                            padding: "3px",
                                          }}
                                        >
                                          <strong>Sleeve Length</strong>
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "left",
                                            padding: "3px",
                                          }}
                                        >
                                          <strong>:</strong>
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "left",
                                            padding: "3px",
                                          }}
                                        >
                                          Short Sleeve
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          style={{
                                            textAlign: "left",
                                            padding: "3px",
                                          }}
                                        >
                                          <strong>Available Colors</strong>
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "left",
                                            padding: "3px",
                                          }}
                                        >
                                          <strong>:</strong>
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "left",
                                            padding: "3px",
                                          }}
                                        >
                                          {availableColors}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          style={{
                                            textAlign: "left",
                                            padding: "3px",
                                          }}
                                        >
                                          <strong>Sizes</strong>
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "left",
                                            padding: "3px",
                                          }}
                                        >
                                          <strong>:</strong>
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "left",
                                            padding: "3px",
                                          }}
                                        >
                                          S, M, L, XL
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item">
                              <h2 className="accordion-header" id="headingTwo">
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseTwo"
                                  aria-expanded="false"
                                  aria-controls="collapseTwo"
                                >
                                  Features
                                </button>
                              </h2>
                              <div
                                id="collapseTwo"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingTwo"
                                data-bs-parent="#accordionExample"
                              >
                                <div className="accordion-body">
                                  <ul
                                    style={{
                                      textAlign: "justify",
                                      paddingLeft: "20px",
                                    }}
                                    className="product-list-disc"
                                  >
                                    <li>
                                      <strong>Ultra-Soft & Comfortable:</strong>{" "}
                                      Made from 100% bamboo viscose, our T-shirt
                                      feels incredibly soft against your skin,
                                      providing unmatched comfort throughout the
                                      day.
                                    </li>
                                    <li>
                                      <strong>
                                        Breathable & Moisture-Wicking:
                                      </strong>{" "}
                                      The natural breathability of bamboo fabric
                                      keeps you cool and dry, making it perfect
                                      for any activity or weather.
                                    </li>
                                    <li>
                                      <strong>Hypoallergenic:</strong> Ideal for
                                      sensitive skin, our bamboo T-shirt is
                                      naturally hypoallergenic and gentle on
                                      your skin.
                                    </li>
                                    <li>
                                      <strong>Eco-Friendly:</strong> Bamboo is a
                                      highly sustainable resource, requiring
                                      minimal water and no pesticides to grow.
                                      By choosing this T-shirt, you are making a
                                      positive impact on the environment.
                                    </li>
                                    <li>
                                      <strong>Durable & Long-Lasting:</strong>{" "}
                                      Bamboo fibers are known for their strength
                                      and durability, ensuring that your T-shirt
                                      will look and feel great wash after wash.
                                    </li>
                                    <li>
                                      <strong>Stylish Fit:</strong> Designed
                                      with a modern fit that flatters all body
                                      types, this T-shirt is perfect for casual
                                      outings, workouts, or lounging at home.
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item">
                              <h2
                                className="accordion-header"
                                id="headingThree"
                              >
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseThree"
                                  aria-expanded="false"
                                  aria-controls="collapseThree"
                                >
                                  Content
                                </button>
                              </h2>
                              <div
                                id="collapseThree"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingThree"
                                data-bs-parent="#accordionExample"
                              >
                                <div className="accordion-body">
                                  <p style={{ textAlign: "justify" }}>
                                    {firstProductData?.sub_category &&
                                    firstProductData?.sub_category ===
                                      "Polo Neck"
                                      ? "95% Bamboo, 5% Spandex"
                                      : "100% Bamboo natural fibre fabric"}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item">
                              <h2 className="accordion-header" id="headingFour">
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseFour"
                                  aria-expanded="false"
                                  aria-controls="collapseFour"
                                >
                                  Care Instructions
                                </button>
                              </h2>
                              <div
                                id="collapseFour"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingFour"
                                data-bs-parent="#accordionExample"
                              >
                                <div className="accordion-body">
                                  {/* {productSpecification?.map((item, i) => (
                                    <div className="availability mb-2" key={i}>
                                      <strong>{item.key} :</strong>
                                      &nbsp;&nbsp;&nbsp;{item.value}
                                    </div>
                                  ))} */}
                                  <ul
                                    style={{
                                      textAlign: "justify",
                                      paddingLeft: "20px",
                                    }}
                                    className="product-list-disc"
                                  >
                                    <li>
                                      <strong>Machine Wash Cold:</strong> To
                                      maintain the quality of your bamboo
                                      T-shirt, wash it in cold water with
                                      similar colors.
                                    </li>
                                    <li>
                                      <strong>Do Not Bleach:</strong> Avoid
                                      using bleach or harsh chemicals.
                                    </li>
                                    <li>
                                      <strong>Hang Dry:</strong> For best
                                      results, hang dry.
                                    </li>
                                    <li>
                                      <strong>Iron Low:</strong> If needed, iron
                                      on low heat on reverse side to smooth out
                                      wrinkles.
                                    </li>
                                    <li
                                      style={{ listStyle: "none" }}
                                      className="mt-2"
                                    >
                                      <em>
                                        ** Since bamboo viscose is a denser
                                        fabric, it may take slightly more time
                                        to dry off completely. **
                                      </em>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>

                            <div className="accordion-item">
                              <h2 className="accordion-header" id="headingFive">
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseFive"
                                  aria-expanded="false"
                                  aria-controls="collapseFive"
                                >
                                  Note
                                </button>
                              </h2>
                              <div
                                id="collapseFive"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingFive"
                                data-bs-parent="#accordionExample"
                              >
                                <div className="accordion-body">
                                  <p style={{ textAlign: "justify" }}>
                                    The digital images we display have the most
                                    accurate color possible.However, due to
                                    differences in computer monitors, there may
                                    be variations in color between the actual
                                    product of your screen.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item">
                              <h2 className="accordion-header" id="headingSix">
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseSix"
                                  aria-expanded="false"
                                  aria-controls="collapseSix"
                                >
                                  Shipping
                                </button>
                              </h2>
                              <div
                                id="collapseSix"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingSix"
                                data-bs-parent="#accordionExample"
                              >
                                <div className="accordion-body">
                                  <p
                                    style={{ textAlign: "justify" }}
                                    className="mb-3"
                                  >
                                    Orders are processed within 1-2 business
                                    days (excluding weekends and holidays) after
                                    receiving your order confirmation email.
                                  </p>
                                  <p
                                    style={{ textAlign: "justify" }}
                                    className="mb-3"
                                  >
                                    Standard Delivery within 5-7 business days.
                                  </p>
                                  <p style={{ textAlign: "justify" }}>
                                    Please refer our
                                    <Link
                                      to={`/shipping-policy`}
                                      target="_blank"
                                    >
                                      <strong> Shipping Policy </strong>
                                    </Link>
                                    Documents for more information.
                                  </p>
                                </div>
                              </div>
                            </div>

                            {/* <div className="accordion-item">
                              <h2
                                className="accordion-header"
                                id="headingSeven"
                              >
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseSeven"
                                  aria-expanded="false"
                                  aria-controls="collapseSeven"
                                >
                                  Reviews
                                </button>
                              </h2>
                              <div
                                id="collapseSeven"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingSeven"
                                data-bs-parent="#accordionExample"
                              >
                                <div className="accordion-body">
                                  <strong>
                                    This is the second item's accordion body.
                                  </strong>{" "}
                                  It is hidden by default, until the collapse
                                  plugin adds the appropriate classes that we
                                  use to style each element. These classes
                                  control the overall appearance, as well as the
                                  showing and hiding via CSS transitions. You
                                  can modify any of this with custom CSS or
                                  overriding our default variables. It's also
                                  worth noting that just about any HTML can go
                                  within the <code>.accordion-body</code>,
                                  though the transition does limit overflow.
                                </div>
                              </div>
                            </div> */}
                          </div>

                          {/* {productSpecification &&
                          productSpecification.length > 0 ? (
                            <div className="availability mb-20">
                              <h5>
                                <b>Specifications: </b>
                              </h5>
                            </div>
                          ) : null} */}
                          {/* {productSpecification?.map((item, i) => (
                            <div className="availability mb-20" key={i}>
                              <h5>{item.key}:</h5>
                              <h5>&nbsp;&nbsp;&nbsp;{item.value}</h5>
                            </div>
                          ))} */}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              {/* similar products */}
              <div className="sidebar-wrapper px-2">
                {/* rating & review */}
                {ratingAndReview && (
                  <div
                    className="p-2 mt-2 mb-4 similar2 d-none"
                    style={{ background: "#fafafa" }}
                  >
                    <label className="fs-6 fw-bold ms-1 mt-2 mb-3">
                      Customer Ratings & Reviews
                    </label>
                    <div className="container">
                      <div className="rating-box">
                        <div className="rating-value d-flex">
                          {parseFloat(
                            ratingAndReview?.average_customer_rating
                          ).toFixed(1)}
                          <span className="star mtn-2">★</span>
                        </div>
                        <div>
                          <div className="ratings-count">
                            {ratingAndReview.customer_rating_count} Ratings
                          </div>
                          <div className="reviews-count">
                            {ratingAndReview.customer_review_count} Reviews
                          </div>
                        </div>
                      </div>

                      <div>
                        {displayedReviews?.map((item, i) =>
                          item.image_url ? (
                            <div className="review-card mt-3" key={i}>
                              <div className="">
                                <div className="reviewer-name">
                                  {item.first_name + " " + item.last_name}
                                </div>
                                <div
                                  className="d-flex"
                                  style={{ height: "30px" }}
                                >
                                  <div className="review-rating">
                                    {item.rating}
                                    <span className="star ms-1">★</span>
                                  </div>
                                  <div
                                    className="ms-2 fs-1"
                                    style={{
                                      marginTop: "-10px",
                                      color: "gray",
                                    }}
                                  >
                                    .
                                  </div>
                                  <div className="review-date mt-1 ms-1">
                                    Posted on{" "}
                                    {new Date(item.created_at)
                                      .toLocaleDateString("en-US", {
                                        year: "numeric",
                                        month: "long",
                                        day: "numeric",
                                      })}
                                  </div>
                                </div>
                              </div>

                              <div className="review-text">
                                {item.review_message &&
                                  item.review_message !== "null" &&
                                  item.review_message}
                              </div>

                              <div className="product-modal2">
                                <div id="displayedImagesAdd2">
                                  {item.image_url
                                    .split("@")
                                    .map((url, index) => (
                                      <img
                                        key={index}
                                        src={url}
                                        alt={`img-${index}`}
                                        style={{ zIndex: 10 }}
                                        data-bs-toggle="modal"
                                        data-bs-target="#imagePreviewModal"
                                        onClick={() => setImageUrl(url)}
                                      />
                                    ))}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="review-card mt-3" key={i}>
                              <div className="">
                                <div className="reviewer-name">
                                  {item.first_name + " " + item.last_name}
                                </div>
                                <div
                                  className="d-flex"
                                  style={{ height: "30px" }}
                                >
                                  <div className="review-rating">
                                    {item.rating}
                                    <span className="star ms-1">★</span>
                                  </div>
                                  <div
                                    className="ms-2 fs-1"
                                    style={{
                                      marginTop: "-10px",
                                      color: "gray",
                                    }}
                                  >
                                    .
                                  </div>
                                  <div className="review-date mt-1 ms-1">
                                    Posted on{" "}
                                    {new Date(item.created_at)
                                      .toLocaleDateString("en-US", {
                                        year: "numeric",
                                        month: "long",
                                        day: "numeric",
                                      })}
                                  </div>
                                </div>
                              </div>

                              <div className="review-text">
                                {item.review_message &&
                                  item.review_message !== "null" &&
                                  item.review_message}
                              </div>
                            </div>
                          )
                        )}
                        {!showAllReviews &&
                          ratingAndReview.customer_review_count > 2 && (
                            <div>
                              <button
                                className="btn btn-link fw-bold mt-2"
                                style={{ textDecoration: "none" }}
                                onClick={() => setShowAllReviews(true)}
                              >
                                Show all reviews
                              </button>
                            </div>
                          )}
                        {showAllReviews &&
                          ratingAndReview.customer_review_count > 2 && (
                            <div>
                              <button
                                className="btn btn-link fw-bold mt-2"
                                style={{ textDecoration: "none" }}
                                onClick={() => setShowAllReviews(false)}
                              >
                                Show less
                              </button>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                )}

                {/* end */}
                {/* <!-- single sidebar start --> */}
                {propularProducts && propularProducts.length > 0 ? (
                  <div className="sidebar-single">
                    <div className="sidebar-title">
                      <h3 className="">similar products</h3>
                    </div>
                    <div className="sidebar-body">
                      <div className="popular-item-inner">
                        {propularProducts?.map((item, i) => (
                          <div className="popular-item" key={i}>
                            <div
                              className="pop-item-thumb"
                              onClick={() => handleProductDetails(item)}
                            >
                              <Link to="javascript:void(0)">
                                <img
                                  src={item.image_url.split("@")[0]}
                                  alt="Product-Img"
                                  className="w-100 h-100 zoom"
                                />
                              </Link>
                            </div>
                            <div className="pop-item-des">
                              <h4 onClick={() => handleProductDetails(item)}>
                                <Link to="javascript:void(0)">
                                  {firstProductData?.product_name} (
                                  {item.color.split(",")[0]})
                                </Link>
                              </h4>
                              <div className="price-box">
                                <span className="regular-price-2 ms-2">
                                  {currencySymbol === "₹" ? "₹" : "$"}
                                  {(item.price * 1).toLocaleString("en-IN", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </span>
                                {item?.price >= item?.MRP ? null : (
                                  <span className="old-price-2 ms-2">
                                    <del>
                                      {currencySymbol === "₹" ? "₹" : "$"}
                                      {(item.MRP * 1).toLocaleString("en-IN", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                    </del>
                                  </span>
                                )}
                                {item?.price >= item?.MRP ? null : (
                                  <div className="ms-0">
                                    <span
                                      className="regular-price-2 ms-2"
                                      style={{
                                        color: "red",
                                        fontSize: "13px",
                                      }}
                                    >
                                      {(
                                        ((item.MRP - item.price) / item.MRP) *
                                        100
                                      ).toFixed(2)}
                                      % off
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                        {/* <!-- end single popular item --> */}
                      </div>
                    </div>
                  </div>
                ) : null}
                {/* <!-- single sidebar end --> */}

                {/* <!-- single sidebar start --> */}
                {/* <div className="sidebar-single">
                  <div className="advertising-thumb fix">
                    <Link to="javascript:void(0)">
                      <img
                        src="assets/img/banner/advertising.jpg"
                        alt=""
                        className="zoom"
                      />
                    </Link>
                  </div>
                </div> */}
                {/* <!-- single sidebar end --> */}
              </div>
              {/* end */}
            </div>
            {/* <div className="col-lg-1"></div> */}
          </div>
        </div>
      </div>
      {/* <!-- page main wrapper end --> */}
    </div>
  );
}
