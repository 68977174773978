import Router from "./Router";
import { useEffect, useState } from "react";

function App() {
  const [isIndia, setIsIndia] = useState(null);
  const [loading, setLoading] = useState(true);

  // Fetch the user's country using IP-based geolocation
  const checkLocation = async () => {
    try {
      const response = await fetch("https://ipapi.co/json/");
      const data = await response.json();

      if (data.country_name === "India") {
        setIsIndia(true);
      } else {
        setIsIndia(true); // false
      }
    } catch (error) {
      console.error("Error fetching location:", error);
      setIsIndia(true); // Default to allow if unable to fetch location
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkLocation();
  }, []);

  // if (loading) {
  //   return <div>Loading...</div>;
  // }

  return (
    <div style={{ position: "relative" }}>
      {/* Your website content */}
      <div style={{ filter: isIndia === false ? "blur(5px)" : "none" }}>
        <Router />
      </div>

      {/* Restriction overlay */}
      {isIndia === false && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.7)", // Semi-transparent background
            color: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <h1>Apologies, this website is unavailable in your region.</h1>
          {/* <p>Please visit us from India to access the content.</p> */}
        </div>
      )}
    </div>
  );
}

export default App;
